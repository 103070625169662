<template>
	<transition name="fade">
		<div v-if="show" class="simple-modal" :class="customClass">
			<div class="simple-modal__backdrop" @click="closeByClick()" />
			<div
				class="simple-modal__dialog"
				:class="{ 'embedded-iframe': inIframe }"
				:style="customMaxWidth > 0 ? `max-width: ${customMaxWidth}px; margin: 0 10px;` : ''"
				aria-modal="true"
			>
				<div :id="modalId" class="simple-modal__body">
					<slot name="body" />
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
export default {
	name: "SimpleModal",
	props: {
		closeOnClick: {
			type: Boolean,
			default: true,
		},
		ariaDescription: {
			type: String,
			default: "Modal",
		},
		modalId: {
			type: String,
			default: "simpleModal",
		},
		customClass: {
			type: String,
			default: "",
		},
		inIframe: {
			type: Boolean,
			default: false,
		},
		customMaxWidth: {
			type: Number,
			default: null,
		},
	},
	data() {
		return {
			show: false,
		};
	},
	created() {
		const _self = this;
		document.addEventListener("keyup", function (event) {
			if (event.key === "Escape" || event.keyCode === 27) {
				_self.closeByClick();
			}
		});
	},
	methods: {
		close() {
			this.show = false;
			document.querySelector("body").classList.remove("overflow-hidden");
			document.querySelector("html").style.overflow = null;
			this.$emit("onClose");
		},
		open() {
			if (this.inIframe) {
				window.scrollTo(0, 0);
			}
			this.show = true;
			document.querySelector("body").classList.add("overflow-hidden");
			document.querySelector("html").style.overflow = "hidden";
			this.$emit("onOpen");
		},
		closeByClick() {
			if (this.closeOnClick) {
				this.close();
			}
		},
	},
};
</script>

<style lang="scss">
body {
	&.overflow-hidden {
		overflow: unset !important;
		overflow-x: hidden !important;
	}
}
.simple-modal {
	position: fixed;
	z-index: 1000001;
	overflow-x: hidden;
	overflow-y: auto;
	top: 0px;
	right: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;
	line-height: 1;
	&__backdrop {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.3);
		z-index: 1200;
	}
	&__dialog {
		position: relative;
		background-color: #ffffff;
		border-radius: 5px;
		z-index: 1200;
		max-height: 100vh;
		overflow-y: auto;
		&.embedded-iframe {
			position: absolute;
			top: 100px;
		}
	}
	&__body {
		overflow: auto;
		display: flex;
		flex-direction: column;
		align-items: stretch;
		height: 100%;
	}
	&__header {
		text-align: right;
		.close-x:hover {
			cursor: pointer;
		}
	}
}
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
	opacity: 0;
}
</style>
