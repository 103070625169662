module.exports = {
    "httpProt": "https://",
    "apiDomain": "api.supermoney.com",
    "apiPort": 443,
    "appDomain": "app.supermoney.com",
    "wwwDomain": "www.supermoney.com",
    "homeDomain": "home.supermoney.com",
    "v1Domain": "admin.supermoney.com",
    "awsBucketURL": "https://s3-us-west-2.amazonaws.com/supermoney-reviews",
    "awsCloudFrontURL": "https://cdn-reviews.supermoney.com",
    "webpEnabled": true
}