<template>
	<div v-if="topProduct" class="top-product-modal">
		<transition name="fade">
			<simple-modal ref="topProductModal" :closeOnClick="true" @onClose="onClose" @onOpen="onOpen">
				<template v-slot:body>
					<div class="top-product-modal-container" role="alertdialog" aria-labelledby="topProductModalContent">
						<p class="modal-dismiss-button" @click="dismissModal" ref="dismissButton" tabindex="0">&#10005;</p>
						<div class="top-product-modal-content" id="topProductModalContent">
							<!-- screen reader text for modal being shown. hidden so the content in this modal isn't read, but the tab focus is moved into the modal -->
							<div aria-hidden="true" class="sr-only" ref="preCta" tabindex="0">modal shown.</div>
							<!-- preheader text -->
							<p class="top-product-preheader">
								<!-- <template v-if="experiment == '1' || experiment == '4'">Before you go...</template> -->
								Not sure which product to look into?
							</p>
							<!-- header -->
							<h2 class="top-product-header">
								<!-- <template v-if="experiment == '1' || experiment == '4'">Check out our best <span class="lowercase">{{ category.name }}</span> pick!</template> -->
								Start with our best <span class="lowercase">{{ category.name }}</span> pick!
							</h2>

							<!-- product box -->
							<div class="top-product-box">
								<img class="top-product-box-best-img" src="/includes/img/bestof/sm-best-badge-xs-yellow.svg" alt="" />
								<div class="top-product-box-top">
									<business-image
										class="business-img"
										imageType="thumb"
										entityType="businesses"
										:business="topProduct.business"
										:parent="topProduct.parent"
										:businessProfile="topProduct"
										:category="category"
										:webpEnabled="webpEnabled"
										:supportedVerticals="supportedVerticals"
										:hideCta="topProduct.hideCta"
									></business-image>
									<div class="name-and-rating">
										<div class="business-name">{{ topProduct.business.name }}</div>
										<div v-if="topProduct.total_vote_count > 4">
											<p v-if="topProduct.nps_rating < -50" class="n-recnd repink rating-description">
												Strongly not recommended
											</p>
											<p v-else-if="topProduct.nps_rating < 0" class="n-recnd rered rating-description">
												Mostly not recommended
											</p>
											<p v-else-if="topProduct.nps_rating < 50" class="n-recnd regreen rating-description">
												Mostly recommended
											</p>
											<p v-else class="n-recnd regreen rating-description">Strongly recommended</p>
										</div>
										<div v-else>
											<p class="nps-not-determined rating-description">
												Rating not yet determined
												<i
													class="fa fa-question-circle-o"
													onmouseover="showTooltip(this)"
													onmouseout="hideTooltip(this)"
													onfocus="showTooltip(this)"
													onfocusout="hideTooltip(this)"
													ontouchstart="toggleTooltip(this)"
													tabindex="0"
													>&nbsp;</i
												>
											</p>
											<span class="nps-rating-description"
												>In our efforts to provide the community with the most accurate information, recommendation
												rating is not determined until a sufficient number of SuperMoney users cast their vote</span
											>
										</div>
									</div>
								</div>
								<!-- <button class="btn visit-site-btn full-width-btn">Visit Site</button> -->
								<template v-if="topProduct.monetized_b">
									<visit-site-btn
										class="visit-site-btn orange-btn"
										:businessProfile="topProduct"
										:supportedVerticals="supportedVerticals"
										:category="category"
										:hideCta="topProduct.hideCta"
									></visit-site-btn>
								</template>
								<template v-else>
									<a
										class="btn btn-primary"
										:href="'/reviews/' + category.nicename + '/' + topProduct.business.nicename"
										>View Profile</a
									>
								</template>
							</div>
						</div>
					</div>
				</template>
			</simple-modal>
		</transition>
	</div>
</template>

<script>
import { mapState } from "vuex";
import BusinessImage from "./../business/BusinessImage.vue";
import ProfileNps from "./ProfileNps.vue";
import BusinessName from "./../business/BusinessName.vue";
import VisitSiteBtn from "./../VisitSiteBtn.vue";
import SimpleModal from "@common/components/SimpleModal.vue";

// Used in Bestof
export default {
	name: "TopProductModal",
	props: {
		category: Object,
		products: Array,
		supportedVerticals: Object,
		webpEnabled: Boolean,
	},
	components: {
		BusinessImage,
		ProfileNps,
		BusinessName,
		VisitSiteBtn,
		SimpleModal,
	},
	data() {
		return {
			dismissed: false,
			scrollPast: false,
			scrolledDown: false,
			currentPos: 0,
			topProduct: {},
		};
	},
	computed: {
		isMobile() {
			return window.innerWidth <= 768 ? true : false;
		},
		...mapState({
			experiment: (state) => state.bestofStore.experiment,
			experimentID: (state) => state.bestofStore.experimentID,
		}),
	},
	created() {
		this.$nextTick(() => {
			if (this.category && this.category.nicename) {
				this.dismissed = sessionStorage.getItem(this.category.nicename + "-top-product-modal-dismissed");
				if (!this.dismissed) {
					this.addScrollPastListener();
				}
			}
		});
		window.addEventListener("closeTopProductModal", this.dismissModal);
	},
	methods: {
		addExitIntentListener() {
			if (this.isMobile) {
				this.currentPos = window.pageYOffset;
				window.addEventListener("scroll", this.mobileExitIntentListener);
			} else {
				document.addEventListener("mouseleave", this.desktopExitIntentListener);
			}
		},
		mobileExitIntentListener() {
			const lastPos = this.currentPos;
			const newPos = window.pageYOffset;
			if (lastPos < newPos) {
				// scrolled down
				if (this.scrolledDown == false) {
					this.scrolledDown = true;
				}
			} else if (lastPos > newPos) {
				// scrolled up
				if (this.scrolledDown == true && !this.dismissed && this.$refs.topProductModal) {
					// scrolled back up
					window.removeEventListener("scroll", this.scrollPastListener);
					window.removeEventListener("scroll", this.mobileExitIntentListener);
					window.removeEventListener("resize", this.scrollPastListener);
					this.$refs.topProductModal.open();
					this.$nextTick(() => {
						$.supermoney.initCtaLinks();
					});
				}
			}
			// set current position
			this.currentPos = newPos;
		},
		desktopExitIntentListener() {
			// exits window
			if (!this.dismissed && this.$refs.topProductModal) {
				document.removeEventListener("mouseleave", this.desktopExitIntentListener);
				window.removeEventListener("scroll", this.scrollPastListener);
				window.removeEventListener("resize", this.scrollPastListener);
				this.$refs.topProductModal.open();
				this.$nextTick(() => {
					$.supermoney.initCtaLinks();
				});
			}
		},
		addScrollPastListener() {
			this.scrollPast = true;
			window.addEventListener("scroll", this.scrollPastListener);
			window.addEventListener("resize", this.scrollPastListener);
		},
		scrollPastListener() {
			let windowTopPosition = window.scrollY - 90;
			let triggerContainers = document.getElementsByClassName("additional-content");
			let triggerContainer, top;
			if (!triggerContainers.length) {
				triggerContainer = document.getElementById("article");
				top = triggerContainer.offsetTop + 90;
			} else {
				triggerContainer = triggerContainers[0];
				top = triggerContainer.offsetTop + 90;
			}

			if (windowTopPosition > top) {
				if (this.scrollPast && !this.dismissed && this.$refs.topProductModal) {
					window.removeEventListener("scroll", this.scrollPastListener);
					window.removeEventListener("resize", this.scrollPastListener);
					document.removeEventListener("mouseleave", this.desktopExitIntentListener);
					window.removeEventListener("scroll", this.mobileExitIntentListener);

					this.topProduct = bestOfData.topProduct;

					if (Object.keys(this.topProduct).length) {
						this.$refs.topProductModal.open();
						this.$nextTick(() => {
							$.supermoney.initCtaLinks();
						});
					}
				}
			}
		},
		dismissModal() {
			if (this.$refs.topProductModal) {
				this.$refs.topProductModal.close();
			}
		},
		onClose() {
			sessionStorage.setItem(this.category.nicename + "-top-product-modal-dismissed", "true");
			this.dismissed = true;
			dataLayer.push({
				event: "top_product_modal_dismissed",
			});
		},
		onOpen() {
			this.$nextTick(() => {
				this.$refs.preCta.focus();
			});
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.top-product-modal {
	.nps-rating-description {
		display: none;
		position: absolute;
		left: 0;
		max-width: 300px;
		min-width: 200px;
		width: 80%;
		background: #eee;
		border: 1px var(--sm-light-gray) solid;
		border-radius: 3px;
		z-index: 100;
		margin-left: 0.5em;
		padding: 0.5em;
		font-size: 0.85em;
		line-height: 1.2;
	}
	.fade-enter-active,
	.fade-leave-active {
		transition: 0.5s;
	}
	.fade-enter,
	.fade-leave-to {
		opacity: 0;
	}
	.fade-leave,
	.fade-enter-to {
		opacity: 1;
	}
	.simple-modal__dialog {
		max-width: 95%;
	}
	.top-product-modal-container {
		.modal-dismiss-button {
			float: right;
			font-size: 22px;
			font-weight: bold;
			padding-right: 0.75em;
			padding-top: 0.25em;
			&:hover {
				cursor: pointer;
			}
		}

		.top-product-modal-content {
			.top-product-preheader {
				margin: 1.5em 1.5em 0;
				text-align: center;
			}
			.top-product-header {
				margin: 0.25em 0.5em 0.5em;
				text-align: center;
				max-width: 100%;
				font-size: 1.5em;

				.lowercase {
					text-transform: lowercase;
				}
			}
			.top-product-box {
				position: relative;
				.top-product-box-best-img {
					position: absolute;
					background-color: white;
					right: 10px;
					width: 25px;
					top: -15px;
				}
				.btn-primary {
					width: 100%;
					font-size: 16px;
					font-weight: bold;
				}
				.top-product-box-top {
					display: flex;
					margin-bottom: 1em;

					.business-img {
						width: 25%;
						display: inline-block;
						padding-left: 0.5em;
					}
					.name-and-rating {
						padding-left: 1em;
						width: 75%;
						display: flex;
						flex-flow: column wrap;
						justify-content: center;

						.business-name {
							font-weight: bold;
							line-height: 1.2;
							font-size: 1.12em;
							margin-bottom: 0.35em;
						}

						.repink {
							color: var(--sm-negative-rating);
						}
						.rered {
							color: var(--sm-negative-rating);
						}
						.regray {
							color: var(--sm-neutral-rating);
						}
						.regreen {
							color: var(--sm-positive);
						}
						.rating-description {
							font-size: 16px;
							font-weight: 700;
							width: auto;
							text-align: left;
							margin: 0 0 5px;
							line-height: 1;
						}
						.reviews-count {
							font-size: 0.85em;
							margin-bottom: 5px;
						}
					}
				}

				.full-width-btn {
					width: 100%;
					border-radius: 4px;
					padding: 0.5em 2em;
					display: inline-block;
					background-color: var(--sm-blue);
					color: #fff;
					border: 1px solid var(--sm-blue);
					border-radius: 4px;
					font-weight: bold;
					font-size: 1.3em;
					&:focus,
					&:active {
						outline: 0;
					}
					&:hover {
						background-color: transparent;
						color: var(--sm-blue);
					}

					&.invalid {
						background-color: lightgray;
						border: lightgray 1px solid;
						border-radius: 4px;
						&:hover {
							cursor: not-allowed;
							color: white;
						}
					}
				}

				border: 1px solid var(--sm-light-gray);
				padding: 1em;
				margin: 0.5em 0.5em 1em;
			}
		}
	}
}
@media (min-width: 600px) {
	.top-product-modal {
		.top-product-modal-container {
			.top-product-modal-content {
				.top-product-header {
					max-width: 80%;
					margin: 0.25em auto 0;
					font-size: 1.8em;
					line-height: 1.3;
				}
				.top-product-box {
					margin: 1.5em 1.5em 2em;
				}
				.top-product-box {
					.top-product-box-best-img {
						right: 10px;
						width: 40px;
						top: -20px;
					}
					.top-product-box-top {
						.business-img {
							padding-left: 1em;
						}
						.name-and-rating {
							padding-left: 1.5em;
							.business-name {
								font-size: 1.3em;
								line-height: 1.4;
							}
						}
					}
				}
			}
		}
	}
}
@media (max-width: 350px) {
	.top-product-modal {
		.top-product-modal-container {
			.top-product-modal-content {
				.top-product-box {
					.top-product-box-top {
						flex-wrap: wrap;
						.business-img {
							padding-left: 0;
							width: 100%;
							max-width: 100px;
							margin: 0 auto 0.5em;
						}
						.name-and-rating {
							width: 100%;
							align-items: center;
							padding-left: 0;
							.business-name {
								text-align: center;
							}
						}
					}
				}
			}
		}
	}
}
</style>
