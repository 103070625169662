import settings from "../../../../common/src/data/settings.js";
import axios from "axios";
axios.defaults.withCredentials = true;

// put this in your settings file if you want to test against the dev node search endpoint
// searchApi: "https://dev.supermoney.com:8000"
const apiUrl = settings.searchApi
	? settings.searchApi
	: settings.httpProt + settings.apiDomain + ":" + settings.apiPort;
const wwwUrl = settings.httpProt + settings.wwwDomain;
let activeToken;

class SearchApi {
	static async search(terms, verticals = null) {
		if (activeToken) {
			activeToken.cancel();
		}
		const CancelToken = axios.CancelToken;
		const source = CancelToken.source();
		activeToken = source;

		try {
			const response = await axios({
				method: "get",
				url: apiUrl + "/search",
				params: { q: terms, v: verticals },
				cancelToken: source.token,
			});
			activeToken = null;
			if (response.data.success) {
				if (response.data.data) {
					return response.data.data;
				} else {
					return [];
				}
			} else {
				return [];
			}
		} catch (e) {
			if (e.isAxiosError) {
				throw e;
			} else {
				return false;
			}
		}
	}
}

export default SearchApi;
