import Vuex from "vuex";
import settings from "../../../common/src/data/settings.js";
import bestofStore from "../../../bestof/src/bestofStore.js";
import Global from "../../../common/src/store/modules/Global.js";
import inputStore from "../../../common/src/components/inputFields/inputStore.js";

Vue.use(Vuex);
Vue.config.devtools = settings.devtools;

export default new Vuex.Store({
	modules: {
		bestofStore,
		Global,
		inputStore,
	},
});
