<template>
	<div class="input-component tax-debt-component credit-score">
		<input
			inputmode="numeric"
			v-model="value"
			@focus="handleFocus"
			@blur="handleBlur"
			aria-label="estimated credit score"
			@keypress="continueOnEnterHandler($event)"
		/>
		<span
			class="input-left-addon"
			:class="{ 'very-good': tier === 'Very Good', good: tier === 'Good', fair: tier === 'Fair' }"
			>{{ tier }}</span
		>
		<div class="slider-bar">
			<vue-slider
				ref="slider"
				v-model="sliderValue"
				:data="data"
				v-bind="options"
				@drag-start="dragStart"
				@drag-end="dragEnd"
				:use-keyboard="true"
				:dot-attrs="{ 'aria-label': ariaLabel }"
				:min="minValue"
				:max="maxValue"
			>
				<template v-slot:process>
					<div class="vue-slider-process custom-class"></div>
				</template>
				<template v-slot:dot>
					<div class="slider-button"><i class="fa fa-chevron-left"></i><i class="fa fa-chevron-right"></i></div>
				</template>
			</vue-slider>
			<div class="slider-marks">
				<div class="slider-marks-left">
					<div>{{ minValue }}</div>
					<div>Poor</div>
				</div>
				<div class="slider-marks-right">
					<div>{{ maxValue }}</div>
					<div>Excellent</div>
				</div>
			</div>
		</div>
		<p v-if="instructions" class="instructions">{{ instructions }}</p>
		<p v-if="errorMsg" class="error-msg">{{ errorMsg }}</p>
		<template v-if="dontKnow">
			<div class="dont-know-section">
				<a @click.stop.prevent="dontKnowHandler" href>I don't know my credit score.</a>
			</div>
		</template>
	</div>
</template>

<script>
import VueSlider from "vue-slider-component";
import InputMixin from "./InputMixin.js";

export default {
	name: "CreditScore",
	components: {
		VueSlider,
	},
	mixins: [InputMixin],
	props: {
		dataSourceName: {
			type: String,
			default: "",
		},
		defaultValue: {
			type: Number,
			default: null,
		},
		inputName: {
			type: String,
			default: "creditScore",
		},
		minValue: {
			type: Number,
			default: 300,
		},
		maxValue: {
			type: Number,
			default: 850,
		},
		interval: {
			type: Number,
			default: 10,
		},
		dontKnow: {
			// used to enable 'don't know credit score' handlling
			type: Boolean,
			default: false,
		},
		lead: {
			type: Object,
			default: null,
		},
		instructions: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			value: 0,
			sliderValue: 0,
			options: {
				tooltip: "none",
				height: 12,
				contained: true,
			},
			errorMsg: null,
			initialLoad: true,
			data: [],
			isInputFocus: false,
			isSliderDragging: false,
			isInitialLoad: true,
			tier: "Fair",
		};
	},
	computed: {
		storeValue() {
			return this.$store && this.$store.state.inputStore.inputFields[this.inputName].value;
		},
	},
	created() {
		for (let i = this.minValue; i <= this.maxValue; i = i + this.interval) {
			this.data.push(i);
		}
	},
	mounted() {
		const localValue = this.storeValue ? this.storeValue : this.defaultValue;
		this.value = localValue;
		this.setTier(this.value);
		this.validate();
	},
	watch: {
		storeValue: function (newVal) {
			this.value = newVal;
			this.validate();
		},
		value() {
			try {
				if (this.value == "650" && this.dontKnow && this.lead && localStorage.getItem("credit-score-unsure")) {
					// this catches when the funnel is restarted, or clicked when the original value was not 650
					this.logUnsureMetric();
				} else {
					localStorage.removeItem("credit-score-unsure");
				}
			} catch (e) {
				// fail silently
				// we're wrapping this in a try/catch because localStorage is not available in iframes in some cases
			}

			if (isNaN(this.value)) {
				this.value = 0;
			}
			this.sliderValue = this.getSliderValue(this.value);
			if (this.value === this.sliderValue || this.value < this.minValue) {
				this.setTier(this.value);
				if (this.value !== null) {
					this.$store && this.$store.dispatch("inputStore/setInputValue", { key: this.inputName, value: this.value });
				}
				this.validate();
				return;
			}
			if (this.value > this.maxValue) {
				this.value = this.maxValue;
			}
			this.value = this.value < this.minValue ? this.minValue : this.value > this.maxValue ? this.maxValue : this.value;
			this.setTier(this.value);
			this.$store && this.$store.dispatch("inputStore/setInputValue", { key: this.inputName, value: this.value });
			this.validate();
		},
		sliderValue(val) {
			if (!this.isInitialLoad && (!this.isInputFocus || this.isSliderDragging)) {
				this.value = val;
			}
			this.isInitialLoad = false;
		},
	},
	methods: {
		validate() {
			return new Promise((resolve) => {
				if (this.value < this.minValue) {
					this.errorMsg = this.minValue + " is the minimum credit score required.";
				} else if (this.value > this.maxValue) {
					this.errorMsg = this.maxValue + " is the maximum credit score you can enter.";
				} else {
					this.errorMsg = null;
				}
				const validation = this.errorMsg === null ? "valid" : "invalid";
				if (this.storeValue != this.value) {
					this.$store &&
						this.$store.dispatch("inputStore/setInputValue", {
							key: this.inputName,
							value: this.value,
							skipLocalStorage: false,
						});
				}
				this.$store &&
					this.$store.dispatch("inputStore/setInputErrorMsg", { key: this.inputName, errorMsg: this.errorMsg });
				resolve(validation);
			});
		},
		getSliderValue(number) {
			let sv = this.minValue;
			for (let i in this.data) {
				if (this.data[i] > number) {
					break;
				}
				sv = this.data[i];
			}
			return sv;
		},
		getValue() {
			return this.value;
		},
		dragStart() {
			this.isSliderDragging = true;
		},
		dragEnd() {
			this.isSliderDragging = false;
		},
		handleBlur() {
			this.isInputFocus = false;
		},
		handleFocus() {
			this.isInputFocus = true;
		},
		setTier(value) {
			if (value < 580) {
				this.tier = "Poor";
			} else if (value < 670) {
				this.tier = "Fair";
			} else if (value < 740) {
				this.tier = "Good";
			} else {
				this.tier = "Very Good";
			}
		},
		dontKnowHandler() {
			// make sure both props exist before attempting
			if (this.dontKnow && this.lead) {
				try {
					localStorage.setItem("credit-score-unsure", true);
				} catch (e) {
					// fail silently
					// we're wrapping this in try catch because localStorage is not available in iframes in some cases
				}
				if (this.value == "650") {
					// this catches the edge case when the user picked 650 before clicking the button, the watcher won't catch that
					this.logUnsureMetric();
				}
				this.value = 650;
				this.$emit("inputFieldSubmit", {
					inputName: this.inputName,
					value: this.value,
					dataSourceName: this.dataSourceName,
					saveMetricOnStepChange: true,
				});
			}
		},
		logUnsureMetric() {
			if (this.dontKnow && this.lead) {
				// need to add new datasource/metric mapping for any new verticals this will be added to
				// mapped verticals: ['personal-loan']
				const metricsData = [
					{
						dataSourceName: `supermoney.${this.lead.vertical}.credit-score-unsure`,
						value: "Unsure",
					},
				];
				this.lead.saveMetricsSwallowError(metricsData);
			}
		},
	},
};
</script>

<style lang="scss">
:root {
	--lighten-percentage: 20%;
	--darken-precentage: 15%;
	--accentColor-s: calc(var(--accentColor) + var(--lighten-percentage));
}

.input-component.tax-debt-component.credit-score {
	position: relative;
	.vue-slider-process.custom-class {
		background-image: linear-gradient(
			to right,
			var(--accentColor, #02465a) 0 50%,
			var(--accentColor, #036380) 50% 67%,
			var(--accentColor, #0481a7) 67% 80%,
			var(--accentColor, var(--sm-blue)) 80% 100%
		);
	}

	input {
		text-align: left;
	}
	.input-left-addon {
		margin-left: -57px;
		font-size: 1.35em;
		color: var(--accentColor, var(--sm-blue));
		&.fair {
			margin-left: -48px;
		}
		&.good {
			margin-left: -68px;
		}
		&.very-good {
			margin-left: -116px;
		}
	}
	.slider-marks-right {
		text-align: right;
	}

	.dont-know-section {
		border-top: 1px solid var(--sm-light-gray);
		border-bottom: 1px solid var(--sm-light-gray);
		padding: 0.6em 0;
		margin: 1.5em 0;
		text-align: center;
		font-weight: 800;
		> a {
			color: var(--linkTextColor, var(--sm-blue));
		}
	}
}
</style>
