export function vueAlert(options) {
	// TODO - set default options
	//let self = this;
	this.$swal({
		title: "Oops...",
		text: "We encountered an error and could not complete your request.",
		type: "error",
		showCloseButton: true,
		showCancelButton: false,
	}).then((result) => {
		// TODO - specify callback?
	});
}

export function getCookie(cname) {
	try {
		var name = cname + "=";
		var decodedCookie = decodeURIComponent(document.cookie);
		var ca = decodedCookie.split(";");
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == " ") {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
	} catch {}

	return "";
}

export function setCookie(cname, cvalue, exdays) {
	try {
		var d = new Date();
		d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
		var expires = "expires=" + d.toUTCString();
		document.cookie = cname + "=" + cvalue + ";" + expires + ";domain=.supermoney.com;path=/";
	} catch {}
}

export function deleteCookie(cname, cvalue) {
	try {
		var d = new Date();
		d.setTime(d.getTime() - 30 * 24 * 60 * 60 * 1000);
		var expires = "expires=" + d.toUTCString();
		document.cookie = cname + "=" + cvalue + ";" + expires + ";domain=.supermoney.com;path=/";
	} catch {}
}

const trackingCookies = {
	_sm_aff_id: "aff_id",
	_sm_aff_sub: "aff_sub",
	_sm_aff_sub2: "aff_sub2",
	_sm_aff_sub3: "aff_sub3",
	_sm_aff_click_id: "aff_click_id",
	_sm_ppcpn: "ppcpn",
	_sm_medium: "medium",
	_sm_campaign: "campaign",
	_sm_term: "term",
	_sm_source: "utm_source",
	_sm_network: "network",
	_sm_sitetarget: "sitetarget",
	_sm_gclid: "gclid",
};

export function getTrackingCookies() {
	const results = {};
	for (const c in trackingCookies) {
		if (trackingCookies.hasOwnProperty(c)) {
			const val = getCookie(c);
			if (val) {
				results[trackingCookies[c]] = val;
			}
		}
	}
	return results;
}

export function makeSlug(inputStr, delimiter) {
	if (!delimiter) {
		delimiter = "_";
	}
	return inputStr
		.toLowerCase()
		.replace(/[^a-z0-9_ ]/g, "")
		.replace(/([_ ]+)/g, delimiter);
}

export function getFlagActive(dataElem = {}, flagName = "", flagValue = 0) {
	// determine if dataElem field flagName matches the value of flagValue
	if (dataElem[flagName] == flagValue) {
		return true;
	}
	return false;
}

export function updateFlag(dataElem = {}, flagName = "", flagValue = 0) {
	// set dataElem field flagName to flagValue
	dataElem[flagName] = flagValue;

	// keep displayable and pdisplayable in sync
	if (flagName == "displayable" && flagValue == false) {
		dataElem.pdisplayable = flagValue;
	}
}

export function imgToThumb(imageUrl) {
	if (!imageUrl || !imageUrl.length) {
		return;
	}
	var posDecimal = 0;
	for (var i = imageUrl.length; i > 0; i--) {
		if (imageUrl[i] == ".") {
			posDecimal = i;
			break;
		}
	}
	return imageUrl.slice(0, posDecimal) + "_thumb" + imageUrl.slice(posDecimal, imageUrl.length);
}

export function formatCurrency(value, decimalPlaces = 2) {
	let numValue = typeof value === "string" || value instanceof String ? parseFloat(value) : value;
	numValue = decimalPlaces > 0 ? numValue : Math.round(numValue);
	var formatter = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
		minimumFractionDigits: decimalPlaces,
	});
	return formatter.format(numValue);
}

export function arrayMove(arr, oldIndex, newIndex) {
	if (arr.length == 2) {
		var elem = arr.splice(newIndex, 1);
		arr.push(elem[0]);
		return;
	}
	if (newIndex >= arr.length) {
		var k = newIndex - arr.length + 1;
		while (k--) {
			arr.push(undefined);
		}
	}
	arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
}

export function setPageDefaults(v) {
	let pageTypes = ["best_of", "localized", "near_me", "quote_funnel_landing_page"];
	let fieldTypes = [
		"mode",
		"nav",
		"title",
		"subtitle",
		"intro",
		"content",
		"alternate",
		"numproducts",
		"topproduct",
		"json",
	];
	for (let i in pageTypes) {
		let pageType = pageTypes[i];
		let pageVar = pageType !== "quote_funnel_landing_page" ? pageType + "_page" : pageType;
		if (!v[pageVar]) {
			v[pageVar] = {};
		}
		for (let j in fieldTypes) {
			let fieldType = fieldTypes[j];
			let fieldVar = "page_" + fieldType;
			if (!v[pageVar][fieldVar]) {
				v[pageVar][fieldVar] = "";
				if (fieldVar == "page_title") {
					if (pageType == "best_of") {
						v[pageVar][fieldVar] = "Best " + v.name;
					} else if (pageType == "quote_funnel_landing_page") {
						v[pageVar][fieldVar] = v.name + " Quote Funnel Landing Page";
					} else {
						v[pageVar][fieldVar] = v.name + " in {{city}}, {{state}}";
					}
				} else if (fieldVar === "page_json") {
					v[pageVar][fieldVar] = { image: "", canonicalUrl: "", defaultLoanReason: "" };
				}
			}
			if (fieldType == "topproduct") {
				fieldVar = "show_" + fieldType;
				if (!v[pageVar][fieldVar]) {
					v[pageVar][fieldVar] = 0;
				}
			} else if (fieldType == "nav") {
				if (!v[pageVar][fieldVar]) {
					v[pageVar][fieldVar] = 0;
				}
			} else if (fieldType == "numproducts") {
				if (!v[pageVar][fieldVar]) {
					v[pageVar][fieldVar] = 5;
				}
			}
		}

		if (!v[pageVar].page_options) {
			v[pageVar]["page_options"] = [];
		}

		if (!v[pageVar].page_options.length) {
			v[pageVar].page_options.push({
				category_id: null,
				option_content: "",
			});
		}
	}
	return v;
}

// ordered by Full Name
export const stateAbbrToFullName = {
	AL: "Alabama",
	AK: "Alaska",
	AS: "American Samoa",
	AZ: "Arizona",
	AR: "Arkansas",
	CA: "California",
	CO: "Colorado",
	CT: "Connecticut",
	DE: "Delaware",
	DC: "District of Columbia",
	FL: "Florida",
	GA: "Georgia",
	GU: "Guam",
	HI: "Hawaii",
	ID: "Idaho",
	IL: "Illinois",
	IN: "Indiana",
	IA: "Iowa",
	KS: "Kansas",
	KY: "Kentucky",
	LA: "Louisiana",
	ME: "Maine",
	MD: "Maryland",
	MA: "Massachusetts",
	MI: "Michigan",
	MN: "Minnesota",
	MS: "Mississippi",
	MO: "Missouri",
	MT: "Montana",
	NE: "Nebraska",
	NV: "Nevada",
	NH: "New Hampshire",
	NJ: "New Jersey",
	NM: "New Mexico",
	NY: "New York",
	NC: "North Carolina",
	ND: "North Dakota",
	MP: "Northern Mariana Islands",
	OH: "Ohio",
	OK: "Oklahoma",
	OR: "Oregon",
	PA: "Pennsylvania",
	PR: "Puerto Rico",
	RI: "Rhode Island",
	SC: "South Carolina",
	SD: "South Dakota",
	TN: "Tennessee",
	TX: "Texas",
	UT: "Utah",
	VT: "Vermont",
	VA: "Virginia",
	VI: "US Virgin Islands",
	WA: "Washington",
	WV: "West Virginia",
	WI: "Wisconsin",
	WY: "Wyoming",
};

// ordered by value
export const stateAbbr = [
	"AK",
	"AL",
	"AR",
	"AS",
	"AZ",
	"CA",
	"CO",
	"CT",
	"DC",
	"DE",
	"FL",
	"GA",
	"GU",
	"HI",
	"IA",
	"ID",
	"IL",
	"IN",
	"KS",
	"KY",
	"LA",
	"MA",
	"MD",
	"ME",
	"MI",
	"MN",
	"MO",
	"MP",
	"MS",
	"MT",
	"NC",
	"ND",
	"NE",
	"NH",
	"NJ",
	"NM",
	"NV",
	"NY",
	"OH",
	"OK",
	"OR",
	"PA",
	"PR",
	"RI",
	"SC",
	"SD",
	"TN",
	"TX",
	"UT",
	"VA",
	"VI",
	"VT",
	"WA",
	"WI",
	"WV",
	"WY",
];

// ordered by Full Name for product profile state data
export const profileStatesData = {
	AL: { name: "Alabama", checked: false },
	AK: { name: "Alaska", checked: false },
	AS: { name: "American Samoa", checked: false },
	AZ: { name: "Arizona", checked: false },
	AR: { name: "Arkansas", checked: false },
	CA: { name: "California", checked: false },
	CO: { name: "Colorado", checked: false },
	CT: { name: "Connecticut", checked: false },
	DE: { name: "Delaware", checked: false },
	DC: { name: "District of Columbia", checked: false },
	FL: { name: "Florida", checked: false },
	GA: { name: "Georgia", checked: false },
	GU: { name: "Guam", checked: false },
	HI: { name: "Hawaii", checked: false },
	ID: { name: "Idaho", checked: false },
	IL: { name: "Illinois", checked: false },
	IN: { name: "Indiana", checked: false },
	IA: { name: "Iowa", checked: false },
	KS: { name: "Kansas", checked: false },
	KY: { name: "Kentucky", checked: false },
	LA: { name: "Louisiana", checked: false },
	ME: { name: "Maine", checked: false },
	MD: { name: "Maryland", checked: false },
	MA: { name: "Massachusetts", checked: false },
	MI: { name: "Michigan", checked: false },
	MN: { name: "Minnesota", checked: false },
	MP: { name: "Northern Mariana Islands", checked: false },
	MS: { name: "Mississippi", checked: false },
	MO: { name: "Missouri", checked: false },
	MT: { name: "Montana", checked: false },
	NE: { name: "Nebraska", checked: false },
	NV: { name: "Nevada", checked: false },
	NH: { name: "New Hampshire", checked: false },
	NJ: { name: "New Jersey", checked: false },
	NM: { name: "New Mexico", checked: false },
	NY: { name: "New York", checked: false },
	NC: { name: "North Carolina", checked: false },
	ND: { name: "North Dakota", checked: false },
	OH: { name: "Ohio", checked: false },
	OK: { name: "Oklahoma", checked: false },
	OR: { name: "Oregon", checked: false },
	PA: { name: "Pennsylvania", checked: false },
	PR: { name: "Puerto Rico", checked: false },
	RI: { name: "Rhode Island", checked: false },
	SC: { name: "South Carolina", checked: false },
	SD: { name: "South Dakota", checked: false },
	TN: { name: "Tennessee", checked: false },
	TX: { name: "Texas", checked: false },
	UT: { name: "Utah", checked: false },
	VT: { name: "Vermont", checked: false },
	VA: { name: "Virginia", checked: false },
	VI: { name: "US Virgin Islands", checked: false },
	WA: { name: "Washington", checked: false },
	WV: { name: "West Virginia", checked: false },
	WI: { name: "Wisconsin", checked: false },
	WY: { name: "Wyoming", checked: false },
};

export const widgetFilters = {
	states: {
		filter_id: "162",
		values: {
			AK: "43",
			AL: "44",
			AR: "45",
			AS: "2220",
			AZ: "47",
			CA: "48",
			CO: "49",
			CT: "50",
			DC: "51",
			DE: "52",
			FL: "53",
			GA: "55",
			GU: "2219",
			HI: "57",
			IA: "58",
			ID: "59",
			IL: "60",
			IN: "61",
			KS: "62",
			KY: "63",
			LA: "64",
			MA: "65",
			MD: "66",
			ME: "67",
			MI: "69",
			MN: "70",
			MO: "71",
			MP: "2221",
			MS: "73",
			MT: "74",
			NC: "75",
			ND: "76",
			NE: "77",
			NH: "78",
			NJ: "79",
			NM: "80",
			NV: "81",
			NY: "82",
			OH: "83",
			OK: "84",
			OR: "85",
			PA: "86",
			PR: "2222",
			RI: "89",
			SC: "90",
			SD: "91",
			TN: "92",
			TX: "93",
			UT: "94",
			VA: "95",
			VI: "2223",
			VT: "97",
			WA: "98",
			WI: "99",
			WV: "100",
			WY: "101",
		},
	},
};

export function isFacebookApp() {
	var ua = navigator.userAgent || navigator.vendor || window.opera;
	return ua.indexOf("FBAN") > -1 || ua.indexOf("FBAV") > -1 || ua.indexOf("Instagram") > -1;
}

export function getQueryParams(queryString = window.location.search) {
	if (queryString != window.location.search && queryString.includes("?")) {
		queryString = "?" + queryString.split("?")[1];
	}

	// TODO - use URLSearchParams() when we decide to not support IE
	queryString = decodeURIComponent(queryString);
	// decode doesnt handle &amp;
	queryString = queryString.replace(/&amp;/g, "&");

	const queryParams = {};
	if (queryString) {
		queryString = queryString.substring(1);
		var params = queryString.split("&");
		for (var p in params) {
			var param = params[p].split("=");
			if (param[1] !== `{${param[0]}}`) {
				queryParams[param[0]] = param[1];
			}
		}
	}
	return queryParams;
}

// from https://stackoverflow.com/questions/37839608/vue-js-text-highlight-filter
export function highlightExact(haystack, needle) {
	if (!needle) {
		return haystack;
	}
	var check = new RegExp(needle, "ig");
	return haystack.toString().replace(check, function (matchedText, a, b) {
		return "<strong>" + matchedText + "</strong>";
	});
}

export function highlightAll(haystack, needle) {
	if (!needle) {
		return haystack;
	}
	var check = new RegExp(("\\b" + needle.trim() + ".*?\\b").replace(/  +/g, " ").replace(/ /g, ".*?\\b|\\b"), "ig");
	return haystack.toString().replace(check, function (matchedText, a, b) {
		return "<strong>" + matchedText + "</strong>";
	});
}

export function stripHtmlContent(value) {
	var div = document.createElement("div");
	div.innerHTML = value;
	for (let child of div.children) {
		div.removeChild(child);
	}
	var text = div.textContent || div.innerText || "";
	return text;
}

export function routeTo(_self, url) {
	_self.$router.push(url);
}

export function stripUtmSource(url) {
	if (typeof url === "string") {
		if (url.includes("utm_source=supermoney")) {
			if (url.includes("&utm_source=supermoney")) {
				url = url.replace("&utm_source=supermoney", "");
			} else if (url.includes("?utm_source=supermoney&")) {
				url = url.replace("utm_source=supermoney&", "");
			} else if (url.includes("?utm_source=supermoney")) {
				url = url.replace("?utm_source=supermoney", "");
			}
		}
		return url;
	} else {
		return "";
	}
}

// TODO - This should live with input fields and with callcenter changes
// planning a refactor on that so fit it in there at that time
export const queryParamsToInputFields = {
	fname: "firstName",
	lname: "lastName",
	pphone: "phone",
	email: "email",
};

export function isBoolOrNum(type_str) {
	return ["boolean", "decimal", "integer"].indexOf(type_str) > -1;
}

export const featureNameMap = {
	loanReason: {
		debt_consolidation: "Debt Consolidation",
		debt_consolidation_credit_card: "Debt Consolidation Credit Card",
		debt_consolidation_tax_debt: "Tax Debt",
		debt_consolidation_auto_refinance: "Auto Refinance",
		debt_consolidation_student_loan_refinancing: "Student Loan Refinancing",
		debt_consolidation_other: "Other Debt Consolidation",
		household_expenses: "Everyday Expenses",
		household_expenses_rent: "Rent",
		household_expenses_bills: "Bills",
		household_expenses_childcare: "Childcare",
		household_expenses_auto_repair: "Auto Repair",
		household_expenses_transportation: "Transportation",
		household_expenses_necessities: "Household Necessities",
		household_expenses_other: "Other Household Expenses",
		home_improvement: "Home Improvement",
		home_improvement_appliances: "Appliances",
		home_improvement_furniture_decorating: "Furniture / Redecorating",
		home_improvement_housing_addition: "Housing Addition",
		home_improvement_hvac: "HVAC",
		home_improvement_landscaping: "Landscaping",
		home_improvement_pest_control: "Pest Control",
		home_improvement_plumbing: "Plumbing",
		home_improvement_pool_spa: "Pool / Spa",
		home_improvement_remodel: "Home Remodel",
		home_improvement_roofing: "Roofing",
		home_improvement_solar: "Solar",
		home_improvement_windows: "Windows / Sliding",
		home_improvement_other: "Other Home Improvement",
		green: "Green",
		wedding: "Engagement / Wedding",
		vacation: "Vacation / Honeymoon",
		baby: "Baby",
		home_buying: "Home Buying",
		large_purchase: "Large Purchase",
		large_purchases_agriculture: "Agriculture",
		large_purchases_automotive: "Automotive",
		large_purchases_baby_childcare_products: "Baby & Childcare Products",
		large_purchases_construction_supplies: "Construction Supplies",
		large_purchases_electronics_appliances: "Electronics & Appliances",
		large_purchases_fashion_apparel: "Fashion & Apparel",
		large_purchases_home_furnishings_decor: "Home Furnishings & Decor",
		large_purchases_jewelry_accessories: "Jewelry & Accessories",
		large_purchases_media_entertainment: "Media & Entertainment",
		large_purchases_medical_devices: "Medical Devices",
		large_purchases_music_arts: "Music & Arts",
		large_purchases_personal_care_products: "Personal Care Products",
		large_purchases_sporting_goods_equipment: "Sporting Goods & Equipment",
		large_purchases_travel_tourism: "Travel & Tourism",
		large_purchases_other: "Other Large Purchases",
		large_purchases_automotive_boat: "Boat",
		large_purchases_automotive_motorcycle: "Motorcycle",
		large_purchases_automotive_rv: "RV",
		large_purchases_automotive_other: "Other Automotive",
		life_event: "Life Event",
		life_event_wedding: "Wedding",
		life_event_vacation: "Vacation",
		life_event_baby: "Baby",
		life_event_funeral: "Funeral",
		life_event_other: "Other Life Event",
		moving_relocation: "Moving or Relocation",
		moving_relocation_rental_deposit: "Rental Deposit",
		moving_relocation_moving_expenses: "Moving Expenses",
		moving_relocation_other: "Other Moving & Relocation",
		auto_private_party_purchase: "Auto",
		auto_repair_body_paint: "Auto Body Repair",
		auto_repair_exhaust_other: "Exhaust & Emissions",
		auto_repair_brake_services_other: "Brake Services",
		auto_repair_engine_services_repair: "Engine Services",
		auto_repair_electrical_systems_other: "Electrical Systems",
		auto_repair_performance_upgrade: "Performance Upgrade",
		auto_repair_steering_and_suspension: "Steering & Suspension",
		auto_repair_tire: "Tires",
		auto_repair_transmission_repair_automatic: "Transmission",
		"auto_repair_tune-up": "Tune Up",
		auto_repair_radiator: "Radiator",
		auto_repair_heating_and_air_conditioning: "Heating & Air Conditioning",
		auto_repair_diagnosis: "Diagnosis",
		auto_repair_windshield_services: "Windshield Services",
		auto_repair_other: "Other Auto Repair",
		business_personal: "Business",
		business_business: "Business Loan",
		medical: "Medical",
		medical_cosmetic_procedures: "Cosmetic Procedures",
		medical_dental: "Dental",
		medical_pregnancy: "Pregnancy",
		medical_pregnancy_other: "Pregnancy",
		medical_alternative: "Alternative Medicine",
		medical_alternative_alternative_other: "Medical Alternative",
		medical_general: "General Medicine",
		medical_general_other: "Medical General",
		medical_specialists: "Medical Specialists",
		medical_specialists_other: "Medical Specialists",
		medical_tourism: "Medical Tourism",
		medical_therapy: "Therapy",
		medical_therapy_other: "Therapy",
		medical_veterinary: "Veterinary",
		medical_weightloss: "Weight Loss",
		medical_weightloss_other: "Weight Loss",
		medical_weightloss_bariatric: "Bariatric",
		medical_weightloss_nonsurgical: "Non Surgical Weight Loss",
		medical_cosmetic: "Cosmetic Procedures",
		medical_other: "Other Medical",
		medical_cosmetic_surgery: "Cosmetic Surgery",
		medical_cosmetic_hair: "Hair Restoration",
		medical_cosmetic_micropigmentation: "Micropigmentation",
		medical_cosmetic_makeup: "Permanent Makeup",
		medical_cosmetic_reconstructive: "Reconstructive Surgery",
		medical_cosmetic_sexual: "Sexual Reassignment Surgery",
		medical_cosmetic_tattoo: "Tattoo Removal",
		medical_cosmetic_vein: "Vein Treatment",
		medical_cosmetic_other: "Other Cosmetic",
		medical_dental_orthodontics: "Orthodontics",
		medical_dental_general: "General Dental",
		medical_dental_other: "Other Dental",
		medical_pregnancy_fertility: "Fertility Treatment",
		medical_pregnancy_midwifery: "Midwifery",
		medical_alternative_accupuncture: "Accupuncture",
		medical_alternative_chiropractic: "Chiropractic",
		medical_alternative_functional: "Functional Medicine",
		medical_alternative_wellness: "Spa & Wellness",
		medical_alternative_stemcell: "Stem Cell",
		medical_alternative_other: "Other Medical Alternative",
		medical_general_internal: "Internal Medicine",
		medical_general_devices: "Medical Devices",
		medical_general_pharmaceuticals: "Pharmaceuticals",
		medical_specialists_dermatology: "Dermatology",
		medical_specialists_gastroenterology: "Gastroenterology",
		medical_specialists_audiology: "Hearing/Audiology",
		medical_specialists_neurosurgery: "Neurosurgery",
		medical_specialists_oncology: "Oncology",
		medical_specialists_orthopedic: "Orthopedic",
		medical_specialists_urology: "Urology",
		medical_therapy_general: "General Therapy",
		medical_therapy_substance: "Substance Abuse/Rehab",
		medical_weightloss_bariatric: "Bariatric",
		medical_weightloss_nonsurgical: "Non Surgical Weight Loss",
		subpurpose_education_career: "Career Education",
		subpurpose_education_college: "College or University",
		career: "Career Education",
		career_art_design: "Art & Design",
		career_animal_care: "Animal Care",
		career_automotive: "Automotive",
		career_beauty_cosmetology: "Beauty & Cosmetology",
		career_business: "Business",
		career_culinary: "Culinary",
		career_healthcare: "Healthcare",
		career_hospitality: "Hospitality",
		career_legal_criminal_justice: "Legal & Criminal Justice",
		career_media_arts: "Media Arts",
		career_skilled_trades: "Skilled Trades",
		career_technology: "Technology",
		career_other: "Other Career",
		auto_dealer_purchase: "Purchase From Dealer",
		auto_private_party_purchase: "Purchase From Private Party",
		auto_lease_purchase: "Lease Buyout",
		auto_refinance: "Refinance",
		auto_repair: "Auto Repair",
		auto_repair_body: "Auto Body Repair",
		auto_repair_exhaust_and_emissions: "Exhaust & Emissions",
		auto_repair_brake_services: "Brake Services",
		auto_repair_engine_services: "Engine Services",
		auto_repair_electrical_systems: "Electrical Systems",
		auto_repair_performance_upgrade: "Performance Upgrade",
		auto_repair_steering_and_suspension: "Steering & Suspension",
		auto_repair_tire: "Tires",
		auto_repair_transmission: "Transmission",
		auto_repair_tune_up: "Tune Up",
		auto_repair_radiator: "Radiator",
		auto_repair_heating_and_air_conditioning: "Heating & Air Conditioning",
		auto_repair_diagnosis: "Diagnosis",
		auto_repair_windshield_services: "Windshield Services",
		auto_repair_other: "Other Repair",
		auto_repair_body_paint: "Paint",
		auto_repair_body_collision: "Collision",
		auto_repair_body_other: "Other Body",
		auto_repair_exhaust_emissions_inspection: "Emissions Inspection",
		auto_repair_exhaust_oxygen_sensor_replacement: "Oxygen Sensor Replacement",
		auto_repair_exhaust_map_sensor_replacement: "Map Sensor Replacement",
		auto_repair_exhaust_manifold_replacement: "Manifold Replacement",
		auto_repair_exhaust_erg_valve_replacement: "ERG Valve Replacement",
		auto_repair_exhaust_catalytic_converter: "Catalytic Converter",
		auto_repair_exhaust_other: "Other Exhaust",
		auto_repair_brake_services_rotor_replacement: "Rotor Replacement",
		auto_repair_brake_services_pad_replacement: "Pad Replacement",
		auto_repair_brake_services_master_cylinder: "Master Cylinder",
		auto_repair_brake_services_abs: "ABS",
		auto_repair_brake_services_other: "Other Brake Services",
		auto_repair_engine_services_ignition_system: "Engine Ignition System Repair",
		auto_repair_engine_services_head_gasket: "Head Gasket Replacement",
		auto_repair_engine_services_valve_cover_gasket: "Valve Cover Gasket Replacement",
		auto_repair_engine_services_fuel_system_repair: "Fuel System Repair",
		auto_repair_engine_services_belt_and_hose: "Belt & Hose Replacement",
		auto_repair_engine_services_repair: "Engine Repair",
		auto_repair_engine_services_replacement: "Engine Replacement",
		auto_repair_engine_services_oil: "Replace Engine Oil",
		auto_repair_engine_services_other: "Other Engine Services",
		auto_repair_electrical_systems_battery_replacement: "Battery Replacement",
		auto_repair_electrical_systems_alternator_replacement: "Alternator Replacement",
		auto_repair_electrical_systems_door_window_motor_replacement: "Door/Window Motor Replacement",
		auto_repair_electrical_systems_starter_replacement: "Starter/Ignition Replacement",
		auto_repair_electrical_systems_fog_lights: "Fog Light Replacement",
		auto_repair_electrical_systems_other: "Other Electrical Systems",
		auto_repair_transmission_repair_automatic: "Automatic Transmission Repair",
		auto_repair_transmission_repair_manual: "Manual Transmission Repair",
		auto_repair_transmission_axle_replacement: "Axle Replacement",
		auto_repair_transmission_clutch_repair_or_replacement: "Clutch Repair or Replacement",
		auto_repair_transmission_driveline_maintenance_and_repair: "Driveline Maintenance & Repair",
		auto_repair_transmission_differential_service_and_repair: "Differential Service & Repair",
		auto_repair_transmission_gear_replacement: "Gear Replacement",
		auto_repair_transmission_other: "Other Transmission",
		funeral_services_cemetery: "Cemetery Property",
		funeral_services_cremation: "Cremation Memorial",
		funeral_services_direct_burial: "Direct Burial",
		funeral_services_direct_cremation: "Direct Cremation",
		funeral_services_traditional: "Traditional Funeral",
		funeral_services_other: "Other Funeral",
		professional_services: "Professional Services",
		professional_services_legal: "Legal Services",
		professional_services_tax: "Tax Relief",
		professional_services_accounting: "Accounting",
		professional_services_other: "Other Professional Services",
		acquire_business_location: "Acquire Business Location",
		emergency_repairs: "Emergency Repairs",
		equipment_purchase: "Equipment Purchase",
		hiring: "Hiring",
		inventory_purchase: "Inventory Purchase",
		lease_improvements: "Lease Improvements",
		marketing: "Marketing",
		payroll: "Payroll",
		product_service_expansion: "Product or Service Expansion",
		technology_investment: "Technology Investment",
		working_capital: "Working Capital",
		other: "Other",
	},
	payFrequency: {
		weekly: "Weekly",
		biweekly: "Biweekly",
		twice_monthly: "Twice a Month",
		monthly: "Monthly",
		other: "Other",
	},
};

export const featureNameMapByNicename = {
	"immigration-status-requirements": {
		us_citizen: "U.S. Citizen",
		us_permanent_resident: "U.S. Permanent Resident",
		non_resident: "Non-Resident",
		non_residents_through_entities: "Non-Residents Through Entities Incorporated in the U.S.",
		temp_resident_visa_holders: "U.S. Temporary Resident Visa Holders",
	},
	"military-status": {
		active_duty_and_dependents: "Active Duty Military",
		non_military: "Non-Military",
		veteran: "Veterans",
		military_dependent: "Military Dependent",
	},
};

export const personalLoanFields = {
	firstName: "supermoney.personal_loan_application.personal-info_first-name",
	lastName: "supermoney.personal_loan_application.personal-info_last-name",
	email: "supermoney.personal_loan_application.personal-info_email",
	loanAmount: "supermoney.personal_loan_application.amount-select",
	loanReason: "supermoney.personal_loan_application.purpose",
	loanReasonMain: "supermoney.personal_loan_application.purpose_main",
	loanReasonLevel2: "supermoney.personal_loan_application.purpose",
	creditScore: "supermoney.personal-loans.credit-score-estimated",
	annualIncome: "supermoney.personal_loan_application.annual-income",
	employmentStatus: "supermoney.personal_loan_application.employment-status",
	payFrequency: "supermoney.personal_loan_application.pay-frequency",
	militaryStatus: "supermoney.personal_loan_application.military-status",
	checkingAccount: "supermoney.personal_loan_application.checking-account",
	housingStatus: "supermoney.personal_loan_application.home-ownership",
	address1: "supermoney.personal_loan_application.personal-street-address",
	city: "supermoney.personal_loan_application.personal-city",
	state: "supermoney.personal_loan_application.personal-state",
	zip: "supermoney.personal_loan_application.personal-zip",
	phone: "supermoney.personal_loan_application.personal-info_phone",
	dob: "supermoney.personal_loan_application.personal-info_dob",
	unsecuredDebt: "supermoney.personal_loan_application.unsecured-debt",
	autoLoanDebt: "supermoney.auto-refi.auto-debt-amount",
	mortgageDebt: "supermoney.personal_loan_application.mortgage-debt",
	studentLoanDebt: "supermoney.student-loan-refinance-application.refi-amount",
	taxDebtAmount: "supermoney.personal_loan_application.tax-debt-owed",
	coBorrower: "supermoney.personal_loan_application.co-borrower",
	ssn: "supermoney.personal_loan_application.ssn",
	firstNameCo: "supermoney.personal_loan_application.personal-info_first-name_co",
	lastNameCo: "supermoney.personal_loan_application.personal-info_last-name_co",
	emailCo: "supermoney.personal_loan_application.personal-info_email_co",
	annualIncomeCo: "supermoney.personal_loan_application.annual-income_co",
	address1Co: "supermoney.personal_loan_application.personal-street-address_co",
	cityCo: "supermoney.personal_loan_application.personal-city_co",
	stateCo: "supermoney.personal_loan_application.personal-state_co",
	zipCo: "supermoney.personal_loan_application.personal-zip_co",
	phoneCo: "supermoney.personal_loan_application.personal-info_phone_co",
	dobCo: "supermoney.personal_loan_application.personal-info_dob_co",
	ssnCo: "supermoney.personal_loan_application.ssn_co",
};

export const taxFields = {
	state: "supermoney.free-tax-consultation-v3.state",
	taxDebtType: "supermoney.free-tax-consultation-v3.governments-owed",
	existingInstallmentAgreement: "supermoney.free-tax-consultation-v3.installment-payments",
	installmentDefaultRisk: "supermoney.free-tax-consultation-v3.installment-default-risk",
	cncStatus: "supermoney.free-tax-consultation-v3.cnc-status",
	cncMonthsLeft: "supermoney.free-tax-consultation-v3.cnc-months-left",
	firstName: "supermoney.free-tax-consultation-v3.first-name",
	lastName: "supermoney.free-tax-consultation-v3.last-name",
	email: "supermoney.free-tax-consultation-v3.email",
	phone: "supermoney.free-tax-consultation-v3.phone",
	taxDebtAmount: "supermoney.free-tax-consultation-v3.debt",
};

export const debtFields = {
	state: "supermoney.debt-settlement-lead-capture.state",
	unsecuredDebt: "supermoney.debt-settlement-lead-capture.debt-owed",
	studentLoanDebt: "supermoney.debt-settlement-lead-capture.student-debt",
	privateStudentDebt: "supermoney.debt-settlement-lead-capture.private-student-debt",
	creditScore: "supermoney.debt-settlement-lead-capture.credit-score-estimated",
	firstName: "supermoney.debt-settlement-lead-capture.first-name",
	lastName: "supermoney.debt-settlement-lead-capture.last-name",
	email: "supermoney.debt-settlement-lead-capture.email",
	phone: "supermoney.debt-settlement-lead-capture.phone",
	loanReasonSelectGrid: "supermoney.debt-settlement-lead-capture.purpose",
	monthlyPaymentsUnpayable: "supermoney.debt-settlement-lead-capture.monthly-payments-unpayable",
};

export const debtQuoteFields = {
	state: "supermoney.debt-settlement-quote-funnel.state",
	unsecuredDebt: "supermoney.debt-settlement-quote-funnel.debt-owed",
	studentLoanDebt: "supermoney.debt-settlement-quote-funnel.student-debt",
	privateStudentDebt: "supermoney.debt-settlement-quote-funnel.private-student-debt",
	creditScore: "supermoney.debt-settlement-quote-funnel.credit-score-estimated",
	firstName: "supermoney.debt-settlement-quote-funnel.first-name",
	lastName: "supermoney.debt-settlement-quote-funnel.last-name",
	email: "supermoney.debt-settlement-quote-funnel.email",
	phone: "supermoney.debt-settlement-quote-funnel.phone",
	loanReasonSelectGrid: "supermoney.debt-settlement-quote-funnel.purpose",
	monthlyPaymentsUnpayable: "supermoney.debt-settlement-quote-funnel.monthly-payments-unpayable",
};

/*
	Description: 
		Takes an array of objects, checks to see if the field exists, and if the field matches a
		value in the given array of values. Returns original value if thas passed value is not an
		array or the array is empty. If the array value is not an object, if the field does not
		exist in the object, or the object's field's value does not exist, it will be filtered out
		of the returned array.
	Arguments:
		arr - array of objects to filter
		field - field name to check
		valuesArr - list of possible values to pass the filtering
*/
export function filterObjArrByField(arr, field, valuesArr) {
	if (Array.isArray(arr) && arr.length) {
		return arr.filter((obj) => {
			if (typeof obj === "object" && field in obj && valuesArr.includes(obj[field])) {
				return true;
			}
			return false;
		});
	}
	return arr;
}

export function startsWithVowel(str) {
	if (typeof str !== "string") {
		// if the value to test is not a string, return false
		return false;
	}

	return /^[aeiou]/i.test(str);
}

export function gtmEvent(eventName, params) {
	if (!eventName) {
		return;
	}
	const payload = { event: eventName };
	if (typeof params == "object") {
		for (const i in params) {
			payload[i] = params[i];
		}
	}
	if (typeof dataLayer != "undefined") {
		dataLayer.push(payload);
	}
}

export function getQuoteFunnelUrl(category, vcategory, prepend = "") {
	try {
		if (
			category &&
			vcategory &&
			vcategory.quote_funnel_landing_page &&
			vcategory.quote_funnel_landing_page.page_mode === "published"
		) {
			return `${prepend}/quotes/${category.nicename === "debt-settlement" ? "debt -relief" : category.nicename}/${
				vcategory.nicename
			}`;
		}

		return `${prepend}/quotes/${category.nicename === "debt-settlement" ? "debt -relief" : category.nicename}`;
	} catch (e) {
		return `${prepend}`;
	}
}

export function campaignsToMetric(campaigns) {
	if (!campaigns || !Array.isArray(campaigns) || campaigns.length === 0) {
		return null;
	}
	const result = [];
	for (const campaign of campaigns) {
		result.push(
			(({ campaignId, laScore, rankScore, rejectedReasons }) => ({ campaignId, laScore, rankScore, rejectedReasons }))(
				campaign
			)
		);
	}
	return result;
}

export function sanitizeNicename(value) {
	return value
		? value
				.trim()
				.toLowerCase()
				.replace(/\s+/g, "-")
				.replace(/[^a-zA-Z0-9-]/g, "")
				.replace(/\-+/g, "-")
		: value;
}
