<template>
	<div>
		<template v-if="category.lead_capture">
			<template v-if="businessProfile.monetized_b && businessProfile.lead_buyer && !hideCta">
				<a
					rel="nofollow"
					href="#"
					@click.prevent="leadCaptureClick($event)"
					class="site-cta lead-capture-cta"
					:class="`${classList} ${businessProfile.business.nicename}-lead-buyer-quote`"
					:data-business-name="businessProfile.business.name"
					:data-business-nicename="businessProfile.business.nicename"
					:data-category-name="category.nicename"
					>{{ leadVerticalText }}</a
				>
				<div
					v-if="!buttonOnly"
					class="cta-quote-requested hide"
					:class="businessProfile.business.nicename + '-lead-buyer-requested'"
				>
					<div class="sm-icon-check-fill" aria-hidden="true"></div>
					&nbsp;
					<p>Quote Requested</p>
				</div>
				<p
					v-if="!buttonOnly"
					class="cta-requested-caption hide hide-mobile"
					:class="businessProfile.business.nicename + '-lead-buyer-requested'"
				>
					Your request for a free quote has been sent to {{ businessProfile.business.name }}. You may be contacted
					shortly.
				</p>
			</template>
			<template v-else-if="url || businessProfile.website">
				<a
					v-if="businessProfile.monetized_b && url && urlParams && urlParams.directlink"
					:id="'link_' + businessProfile.business.nicename"
					rel="nofollow"
					data-lead-gen="1"
					:href="url"
					class="btn-split site-cta lead-capture-cta sm-directlink"
					:class="classList"
					:data-business-name="businessProfile.business.name"
					:data-business-nicename="businessProfile.business.nicename"
					:data-category-name="category.nicename"
					>{{ buttonText }}</a
				>
				<a
					v-else
					:onclick="
						'openVisitSiteLightbox(event, \'' +
						businessProfile.business.name +
						'\', \'' +
						businessProfile.category.name +
						'\', \'' +
						businessProfile.category.nicename +
						'\');'
					"
					:id="'link_' + businessProfile.business.nicename"
					rel="nofollow"
					data-lead-gen="1"
					:href="url"
					class="btn-split site-cta lead-capture-cta"
					:class="classList"
					:data-business-name="businessProfile.business.name"
					:data-business-nicename="businessProfile.business.nicename"
					:data-category-name="category.nicename"
					>{{ buttonText }}</a
				>
				<a
					v-if="!buttonOnly"
					:id="'link_' + businessProfile.business.nicename + '_oe'"
					rel="nofollow"
					:href="quoteFunnelUrl"
					class="hide sm-cta site-cta"
					target="_blank"
					>{{ ctaButtonText }}</a
				>
				<a
					v-if="!buttonOnly"
					rel="nofollow"
					class="btn-cta-a btn-split sm-cta skip-capture-cta hide"
					target="_blank"
					:href="url"
					>{{ buttonText }}</a
				>
			</template>
		</template>
		<template v-else-if="supportedVerticals[category.nicename] && !hideCta">
			<template v-if="businessProfile.monetized_b && businessProfile.api_partner">
				<a
					v-if="url && urlParams && urlParams.directlink"
					:id="'link_' + businessProfile.business.nicename"
					rel="nofollow"
					:href="url"
					target="_blank"
					:data-business-name="businessProfile.business.name"
					:data-business-nicename="businessProfile.business.nicename"
					:data-category-name="category.nicename"
					:data-no-partner-link="true"
					class="site-cta sm-directlink"
					:class="classList"
					>Apply Now</a
				>
				<a
					v-else-if="url"
					:onclick="
						'openVisitSiteLightbox(event, \'' +
						businessProfile.business.name +
						'\', \'' +
						businessProfile.category.name +
						'\', \'' +
						businessProfile.category.nicename +
						'\');'
					"
					:id="'link_' + businessProfile.business.nicename"
					rel="nofollow"
					:href="url"
					target="_blank"
					:data-business-name="businessProfile.business.name"
					:data-business-nicename="businessProfile.business.nicename"
					:data-category-name="category.nicename"
					:data-no-partner-link="true"
					class="site-cta go-test-text"
					:class="classList"
					>{{ offerEngineText }}</a
				>
				<a
					v-else
					:onclick="
						'openVisitSiteLightbox(event, \'' +
						businessProfile.business.name +
						'\', \'' +
						businessProfile.category.name +
						'\', \'' +
						businessProfile.category.nicename +
						'\');'
					"
					:id="'link_' + businessProfile.business.nicename"
					rel="nofollow"
					:href="
						['personal-loans'].includes(category.nicename)
							? quoteFunnelUrl + '/' + businessProfile.business.nicename
							: quoteFunnelUrl
					"
					target="_blank"
					:data-business-name="businessProfile.business.name"
					:data-business-nicename="businessProfile.business.nicename"
					:data-category-name="category.nicename"
					:data-no-partner-link="true"
					class="site-cta go-test-text"
					:class="classList"
					>{{ offerEngineText }}</a
				>
				<a
					v-if="!buttonOnly"
					:id="'link_' + businessProfile.business.nicename + '_oe'"
					rel="nofollow"
					:href="
						['personal-loans'].includes(category.nicename)
							? quoteFunnelUrl + '/' + businessProfile.business.nicename
							: quoteFunnelUrl
					"
					target="_blank"
					class="hide sm-cta site-cta offer-engine-link"
					>{{ ctaButtonText }}</a
				>
			</template>
			<template v-else-if="businessProfile.monetized_b && !businessProfile.api_partner && url">
				<a
					v-if="url && urlParams && urlParams.directlink"
					:id="'link_' + businessProfile.business.nicename"
					rel="nofollow"
					:href="url"
					target="_blank"
					:data-business-name="businessProfile.business.name"
					:data-business-nicename="businessProfile.business.nicename"
					:data-category-name="category.nicename"
					class="site-cta sm-directlink"
					:class="classList"
					>{{ buttonText }}</a
				>
				<a
					v-else
					:onclick="
						'openVisitSiteLightbox(event, \'' +
						businessProfile.business.name +
						'\', \'' +
						businessProfile.category.name +
						'\', \'' +
						businessProfile.category.nicename +
						'\');'
					"
					:id="'link_' + businessProfile.business.nicename"
					rel="nofollow"
					:href="url"
					target="_blank"
					:data-business-name="businessProfile.business.name"
					:data-business-nicename="businessProfile.business.nicename"
					:data-category-name="category.nicename"
					class="site-cta"
					:class="classList"
					>{{ buttonText }}</a
				>
				<a
					v-if="!buttonOnly"
					:id="'link_' + businessProfile.business.nicename + '_oe'"
					rel="nofollow"
					:href="quoteFunnelUrl"
					target="_blank"
					class="hide sm-cta site-cta offer-engine-link"
					>{{ ctaButtonText }}</a
				>
			</template>
		</template>
		<template v-else-if="!hideCta">
			<template v-if="businessProfile.monetized_b && url">
				<a
					v-if="urlParams && urlParams.directlink"
					rel="nofollow"
					class="site-cta sm-directlink"
					:class="classList"
					target="_blank"
					:href="url"
					:data-business-name="businessProfile.business.name"
					:data-business-nicename="businessProfile.business.nicename"
					:data-category-name="category.nicename"
					>{{ buttonText }}</a
				>
				<a
					v-else-if="['checking-accounts', 'personal-credit-cards'].includes(category.nicename)"
					rel="nofollow"
					class="site-cta"
					:class="classList"
					:onclick="'openQualifier(event, this)'"
					:href="url"
					:data-business-name="businessProfile.business.name"
					:data-business-nicename="businessProfile.business.nicename"
					:data-category-name="category.nicename"
					:data-virtual-name="vcategory && vcategory.nicename ? vcategory.nicename : ''"
					>{{ buttonText }}</a
				>
				<a
					v-else
					rel="nofollow"
					class="site-cta"
					:class="classList"
					target="_blank"
					:href="
						url +
						(experimentID == 'oTf8pov2QBisZywH0PeDog' || experimentID == 'KO4WUTVnTXq1aO3Cb8RXIw'
							? '&adv_sub3=exp-' + trackingParameterforExp
							: '')
					"
					:data-business-name="businessProfile.business.name"
					:data-business-nicename="businessProfile.business.nicename"
					:data-category-name="category.nicename"
					>{{ customCtaText ? customCtaText : buttonText }}</a
				>
				<template v-if="offerId && affId && !buttonOnly">
					<img
						class="lazyload"
						src="https://home.supermoney.com/includes/img/common/1x1.trans.gif"
						:data-src="'https://super.go2cloud.org/aff_i?offer_id=' + offerId + '&aff_id=' + affId"
						width="1"
						height="1"
						alt=""
					/>
					<noscript
						><img
							:src="'https://super.go2cloud.org/aff_i?offer_id=' + offerId + '&aff_id=' + affId"
							width="1"
							height="1"
							alt=""
					/></noscript>
				</template>
			</template>
		</template>
	</div>
</template>

<script>
import { mapState } from "vuex";
import settings from "../data/settings.js";
import { getQuoteFunnelUrl } from "../helpers";
import { getQueryParams } from "./../helpers/index.js";

// Used in Bestof, Localized
export default {
	name: "VisitSiteBtn",
	props: {
		businessProfile: Object,
		supportedVerticals: Object,
		category: Object,
		vcategory: Object,
		stickyProductCta: Boolean,
		hideCta: {
			type: Boolean,
			default: false,
		},
		urlParams: Object,
		class: String,
		overrideCtaText: String,
		overrideClass: String,
		overrideUrl: String,
		buttonOnly: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			offerId: null,
			affId: null,
			buttonText: "Visit Site",
			ctaButtonText: "Get Personalized Rates",
			trackingParameterforExp: "visit-site",
			leadVerticalText: "Get Quote",
			offerEngineText: "Apply Now",
			customCtaText: "Visit Site",
			classList: "btn-cta-a",
			url: null,
			quoteFunnelUrl: "",
		};
	},
	computed: {
		...mapState({
			experiment: (state) => state.bestofStore.experiment,
			experimentID: (state) => state.bestofStore.experimentID,
		}),
	},
	watch: {
		experiment(newVal) {
			if (
				newVal == 1 &&
				(this.experimentID == "oTf8pov2QBisZywH0PeDog" || this.experimentID == "KO4WUTVnTXq1aO3Cb8RXIw")
			) {
				this.buttonText = "View Rates";
				this.trackingParameterforExp = "view-rates";
			}
		},
	},
	created() {
		this.getQuoteFunnelUrl();

		if (this.category.OfferEngine) {
			this.ctaButtonText = this.category.OfferEngine.ctaButtonText;
		}
		if (this.businessProfile.buttonText) {
			this.buttonText = this.businessProfile.buttonText;
		}
		if (this.category.custom_cta_text) {
			this.leadVerticalText = this.category.custom_cta_text;
			this.offerEngineText = this.category.custom_cta_text;
			this.customCtaText = this.category.custom_cta_text;
		}
		this.url = this.businessProfile.website_track ? this.businessProfile.website_track : this.businessProfile.website;
		if (this.overrideUrl) {
			this.url = this.overrideUrl;
		}
		if (this.url) {
			let qp = getQueryParams(this.url);
			this.offerId = qp.offer_id;
			if (this.offerId) {
				this.affId = qp.aff_id ? qp.aff_id : "2";
			}
		}
		if (this.overrideCtaText) {
			this.leadVerticalText = this.overrideCtaText;
			this.offerEngineText = this.overrideCtaText;
			this.customCtaText = this.overrideCtaText;
			this.buttonText = this.overrideCtaText;
		}
		if (this.overrideClass !== undefined) {
			this.classList = this.overrideClass;
		}
	},
	methods: {
		leadCaptureClick(event) {
			leadCaptureClick(event.target);
		},
		getQuoteFunnelUrl() {
			this.quoteFunnelUrl = getQuoteFunnelUrl(
				this.category,
				this.vcategory,
				`${settings.httpProt}${settings.wwwDomain}`
			);
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.cta-quote-requested {
	color: var(--ratingPositiveColor, var(--sm-positive));
	background-color: var(--sm-positive-alpha5);
	display: block;
	padding: 15px 0.5em 0 0.5em;
	text-align: center;
	line-height: 1.2em;
	font-weight: bold;
	font-size: 16px;
	border-radius: 4px;

	div,
	p {
		display: inline-block;
	}

	.sm-icon-check-fill {
		float: unset !important;
		color: var(--ratingPositiveColor, var(--sm-positive)) !important;
	}
}

.cta-requested-caption {
	margin-top: 0.4em;
	line-height: 1.1em;
	color: #808080;
	font-size: 12px;
	text-align: center;
}
</style>
