<template>
	<div class="vue-app personal-loan-savings-calculator">
		<div class="credit-card-debt-amount row-1">
			<div class="savings-label">How much credit card debt do you owe?</div>
			<div class="slider">
				<div class="value">
					{{ formattedValue }}
				</div>
				<div class="slider-bar">
					<vue-slider
						ref="slider"
						v-model="sliderValue"
						:data="data"
						v-bind="options"
						:use-keyboard="true"
						:dot-attrs="{ 'aria-label': 'Credit car debt' }"
						:min="minValue"
						:max="maxValue"
					>
						<template v-slot:dot>
							<div class="slider-button"><i class="fa fa-chevron-left"></i><i class="fa fa-chevron-right"></i></div>
						</template>
					</vue-slider>
					<div class="slider-marks">
						<div class="slider-marks-left">{{ minLabel }}</div>
						<div class="slider-marks-right">{{ maxLabel }}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="potential-savings">
			<div class="savings-wrapper">
				<div>
					<strong>Your Potential Savings</strong>
				</div>
				<div class="normal">With a Personal Loan</div>
			</div>
			<div class="savings-value">
				{{ savings }}
			</div>
			<img
				class="piggybank-img lazyload hide-mobil"
				src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
				data-src="/includes/img/loanLander/piggy.svg"
				alt=""
			/>
		</div>
		<div class="modal-link">
			<button class="no-border" @click="openModal">
				<i class="fa fa-question-circle"></i> How is this calculated?
			</button>
		</div>
		<SimpleModal ref="modal" class="supermoney" :closeOnClick="true" :ariaDescription="'How is this calculated'">
			<template v-slot:body>
				<div class="sim-modal-header">
					<div class="sim-modal-body">
						<h2 tabindex="1" aria-label="Request a free tax relief quote">How are the estimated savings calculated?</h2>
						<span class="sm-icon-times-light" aria-hidden="true" @click="closeModal"></span>
					</div>
				</div>
				<div class="sim-modal-body">
					<p>
						The estimated potential savings is calculated by taking the difference between the total repayment cost of a
						personal loan and the total repayment cost of credit card debt.
					</p>
					<p>In order to calculate the potential savings, we need to make some assumptions listed below.</p>
					<ul>
						<li><strong>Calculating the credit card total repayment cost:</strong></li>
						<ul>
							<li>
								We're assuming that the applicant has a credit score higher than 740. The average APR of a credit card
								for users with excellent credit above 740 tends to be around 18.4% APR according to U.S. News.
							</li>
							<li>
								We're assuming that the credit card debt must be paid off in 36 months with equal monthly payments
								on-time.
							</li>
							<li>
								Then, based on the credit score and the total credit card debt amount you specified, we calculate the
								total repayment cost estimate of the credit card debt.
							</li>
						</ul>
						<li class="top-padding"><strong>Calculating the personal loan total repayment cost:</strong></li>
						<ul>
							<li>
								We're assuming a personal loan APR of 11.47% based on SuperMoney's historical data on pre-qualified
								offers for applicants with a credit score higher than 740. This APR is the median value of all the the
								lowest APRs that our applicants with over 740 credit score pre-qualified for on SuperMoney.
							</li>
							<li>We're assuming that the loan must be paid off in 36 months with equal monthly payments on-time.</li>
							<li>
								Then, we take the credit card debt amount you entered as the loan amount. With that and the credit score
								you specified, we calculate the total repayment cost estimate of a personal loan.
							</li>
						</ul>
					</ul>
					<p>
						The potential savings calculated here is not representative of your actual savings or your qualifications.
						It is not a guarantee of pre-qualification through SuperMoney's loan offer engine. Your actual savings and
						qualifications will vary based on specifics of your current financial situation, repayment terms, interest
						rates, credit score, and additional factors. The only way to see the rates and terms you may qualify for is
						to apply by clicking the "Get Personalized Rates" button below and submitting an application.
					</p>
				</div>
			</template>
		</SimpleModal>
	</div>
</template>

<script>
import VueSlider from "vue-slider-component";
import "../../../../common/src/components/inputFields/inputFields.scss";
import { formatCurrency } from "../../../../common/src/helpers";
import SimpleModal from "../../../../common/src/components/SimpleModal.vue";

export default {
	name: "PersonalLoanCalculator",
	components: {
		VueSlider,
		SimpleModal,
	},
	data() {
		return {
			sliderValue: 5000,
			options: {
				tooltip: "none",
				height: 12,
				contained: true,
			},
			minValue: 500,
			maxValue: 100000,
			minLabel: "$500",
			maxLabel: "$100,000+",
			data: [
				500, 750, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000, 7500, 10000, 12500, 15000, 20000, 25000, 30000,
				35000, 40000, 50000, 75000, 100000,
			],
			savings: null,
		};
	},
	computed: {
		formattedValue() {
			return formatCurrency(this.sliderValue, 0);
		},
	},
	created() {
		this.minValue = this.data[0];
		this.maxValue = this.data[this.data.length - 1];
		this.minLabel = formatCurrency(this.minValue, 0);
		this.maxLabel = formatCurrency(this.maxValue, 0) + "+";
		this.calculateSavings(this.sliderValue);
	},
	watch: {
		sliderValue(newVal) {
			this.calculateSavings(newVal);
		},
	},
	methods: {
		calculateSavings(debt) {
			debt = parseInt(debt);
			// Calc based on monthly payments
			let cardApr = 0.1804 / 12;
			// The logic to get the loanApr is take all apps with 740+ credit score,
			// get the min apr offerd in each app and take the median of the list of aprs.
			let loanApr = 0.1173 / 12;

			const termInMonths = 36;

			const monthlyPayment =
				(debt * cardApr * Math.pow(1 + cardApr, termInMonths)) / (Math.pow(1 + cardApr, termInMonths) - 1);
			const totalPaidCreditCard = monthlyPayment * termInMonths;

			const monthlyPaymentLoan =
				(debt * loanApr * Math.pow(1 + loanApr, termInMonths)) / (Math.pow(1 + loanApr, termInMonths) - 1);
			const totalPaidLoan = monthlyPaymentLoan * termInMonths;

			this.savings = formatCurrency(totalPaidCreditCard - totalPaidLoan, 0);
		},
		openModal() {
			this.$refs.modal.open();
		},
		closeModal() {
			this.$refs.modal.close();
		},
	},
};
</script>

<style lang="scss">
.personal-loan-savings-calculator {
	display: flex;
	flex-flow: column;
	.modal-link {
		width: 100%;
		text-align: right;
		margin-top: 5px;
	}
	.row-1 {
		display: flex;
		flex-flow: column;
		align-items: center;
		padding: 1em 1.5em;
		.slider,
		.savings-value {
			flex: 1;
		}
		.savings-label {
			margin-bottom: 0.75em;
			padding-top: 0.25em;
		}
		&.credit-card-debt-amount {
			margin: 0 1em;
			border-top: 1px solid var(--sm-light-gray);
			border-left: 1px solid var(--sm-light-gray);
			border-right: 1px solid var(--sm-light-gray);
			border-bottom: 1px solid var(--sm-light-gray);
		}
		@media (min-width: 550px) {
			flex-flow: row;
			.savings-label {
				flex-basis: 50%;
				padding-right: 10px;
				padding-top: 0;
				margin-bottom: 0;
			}
		}
	}
	.potential-savings {
		border-radius: 5px;
		border: 3px solid var(--sm-positive);
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		padding: 20px;
		.savings-wrapper {
			display: flex;
			flex-flow: column;
			justify-content: center;
			font-size: 20px;
			line-height: 1.2;
			.normal {
				font-size: 16px;
			}
		}
		.savings-value {
			display: flex;
			justify-content: space-between;
			font-size: 40px;
			color: var(--sm-positive);
			font-weight: bold;
			img {
				display: none;
			}
		}
		@media (max-width: 550px) {
			.hide-mobil {
				display: none;
			}
		}
		@media (max-width: 420px) {
			flex-flow: row wrap;
			justify-content: center;
			.savings-wrapper {
				width: 100%;
				text-align: center;
			}
			.savings-value {
				justify-content: center;
			}
		}
	}
	.savings-label {
		font-weight: bold;
		font-size: 20px;
		line-height: 1;
	}
	.input-component {
		&.tax-debt-component {
			width: 100%;
		}
		input {
			width: 100%;
		}
	}
	.slider {
		width: 100%;
		.value {
			color: var(--sm-blue);
			font-size: 1.65em;
			font-weight: bold;
		}
		.slider-bar {
			margin: 0px;
		}
	}

	.simple-modal__dialog {
		max-width: 700px;
	}
	.simple-modal__body {
		text-align: left;
		h2 {
			font-size: 1.4em;
		}
		p {
			font-size: 0.9em;
		}
		li {
			font-size: 0.9em;
		}
	}
	.sim-modal-header {
		border-bottom: 1px solid var(--sm-light-gray);
		padding: 1em;
		.sim-modal-body {
			display: flex;
			justify-content: space-between;
			padding: 0;
			span {
				display: flex;
				align-items: center;
			}
		}
	}
	.sim-modal-body {
		padding: 0.5em 1em;
		ul {
			line-height: 1.4;
			padding-left: 1em;
			li.top-padding {
				margin-top: 0.5em;
			}
		}
	}
	.sm-icon-times-light:hover {
		cursor: pointer;
	}
}
</style>
