<template>
	<div>
		<business-link
			:businessProfile="businessProfile"
			:supportedVerticals="supportedVerticals"
			:category="category"
			:vcategory="vcategory"
			:stickyProductCta="stickyProductCta"
			:hideCta="hideCta"
			:urlParams="urlParams"
		>
			<template v-if="webpEnabled">
				<picture>
					<source
						type="image/webp"
						srcset="https://home.supermoney.com/includes/img/common/1x1.trans.gif"
						:data-srcset="url + '.webp'"
					/>
					<source
						:type="getMimetype()"
						srcset="https://home.supermoney.com/includes/img/common/1x1.trans.gif"
						:data-srcset="url"
					/>
					<img
						class="lazyload result-company-img business-image"
						src="https://home.supermoney.com/includes/img/common/1x1.trans.gif"
						:data-src="url"
						:data-business-name="businessProfile.business.name"
						:data-business-nicename="businessProfile.business.nicename"
						:data-category-name="category.nicename"
						:alt="business.name"
					/>
				</picture>
			</template>
			<template v-else>
				<img
					class="lazyload result-company-img business-image"
					src="https://home.supermoney.com/includes/img/common/1x1.trans.gif"
					:data-src="url"
					:data-business-name="businessProfile.business.name"
					:data-business-nicename="businessProfile.business.nicename"
					:data-category-name="category.nicename"
					:alt="business.name"
				/>
			</template>
		</business-link>
	</div>
</template>

<script>
// Used in Bestof, Localized
import settings from "./../../data/settings.js";
import BusinessLink from "./BusinessLink.vue";

export default {
	name: "BusinessImage",
	components: {
		BusinessLink,
	},
	props: {
		imageType: String,
		entityType: String,
		business: Object,
		parent: Object,
		businessProfile: Object,
		category: Object,
		vcategory: Object,
		webpEnabled: Boolean,
		supportedVerticals: Object,
		stickyProductCta: Boolean,
		hideCta: Boolean,
		urlParams: Object,
	},
	data() {
		return {
			url: this.business.image
				? `${settings.awsCloudFrontURL || settings.awsBucketURL}/${this.entityType}/${("" + this.business.id)[0]}/${
						this.business.image.split(".")[0]
				  }_${this.imageType}.${this.business.image.split(".")[1]}`
				: `${settings.awsCloudFrontURL || settings.awsBucketURL}/${this.entityType}/${("" + this.parent.id)[0]}/${
						this.parent.image.split(".")[0]
				  }_${this.imageType}.${this.parent.image.split(".")[1]}`,
		};
	},
	methods: {
		getMimetype() {
			const img = this.business.image ? this.business.image : this.parent.image;
			let ext = img.split(".")[1];
			if (ext == "jpg") {
				ext = "jpeg";
			}
			if (["jpeg", "gif", "png"].indexOf(ext) !== -1) {
				return `image/${ext}`;
			}
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.business-image {
	width: 100%;
}
</style>
