<template>
	<span>
		<template v-if="hideCta">
			<a
				:href="`/reviews/${businessProfile.category.nicename}/${businessProfile.business.nicename}`"
				target="_blank"
				:data-business-name="businessProfile.business.name"
				:data-category-name="category.nicename"
				><slot></slot
			></a>
		</template>
		<template v-else>
			<template v-if="category.lead_capture">
				<template v-if="businessProfile.monetized_b && businessProfile.lead_buyer">
					<a
						rel="nofollow"
						href="#"
						@click.prevent="leadCaptureClick($event)"
						class="site-cta lead-capture-cta"
						:class="businessProfile.business.nicename + '-lead-buyer-quote'"
						:data-business-name="businessProfile.business.name"
						:data-business-nicename="businessProfile.business.nicename"
						:data-category-name="category.nicename"
						><slot></slot
					></a>
					<a
						class="hide"
						:class="businessProfile.business.nicename + '-lead-buyer-requested'"
						:href="`/reviews/${businessProfile.category.nicename}/${businessProfile.business.nicename}`"
						target="_blank"
						:data-business-name="businessProfile.business.name"
						:data-category-name="category.nicename"
						><slot></slot
					></a>
				</template>
				<template v-else-if="businessProfile.website_track || businessProfile.website">
					<a
						v-if="businessProfile.monetized_b && businessProfile.website_track && urlParams && urlParams.directlink"
						:id="'link_' + businessProfile.business.nicename"
						rel="nofollow"
						data-lead-gen="1"
						:href="businessProfile.website_track"
						class="site-cta lead-capture-cta sm-directlink"
						:data-business-name="businessProfile.business.name"
						:data-business-nicename="businessProfile.business.nicename"
						:data-category-name="category.nicename"
						><slot></slot
					></a>
					<a
						v-else
						:onclick="
							'openVisitSiteLightbox(event, \'' +
							businessProfile.business.name +
							'\', \'' +
							businessProfile.category.name +
							'\', \'' +
							businessProfile.category.nicename +
							'\');'
						"
						:id="'link_' + businessProfile.business.nicename"
						rel="nofollow"
						data-lead-gen="1"
						:href="businessProfile.website_track ? businessProfile.website_track : businessProfile.website"
						class="site-cta lead-capture-cta"
						:data-business-name="businessProfile.business.name"
						:data-business-nicename="businessProfile.business.nicename"
						:data-category-name="category.nicename"
						><slot></slot
					></a>
					<a
						:id="'link_' + businessProfile.business.nicename + '_oe'"
						rel="nofollow"
						:href="quoteFunnelUrl"
						class="hide sm-cta site-cta"
						target="_blank"
						>{{ ctaButtonText }}</a
					>
				</template>
				<template v-else>
					<a
						:href="`/reviews/${businessProfile.category.nicename}/${businessProfile.business.nicename}`"
						target="_blank"
						:data-business-name="businessProfile.business.name"
						:data-category-name="category.nicename"
						><slot></slot
					></a>
				</template>
			</template>
			<template v-else-if="supportedVerticals[category.nicename]">
				<template v-if="businessProfile.monetized_b && businessProfile.api_partner">
					<a
						v-if="businessProfile.website_track && urlParams && urlParams.directlink"
						:id="'link_' + businessProfile.business.nicename"
						rel="nofollow"
						:href="businessProfile.website_track"
						target="_blank"
						:data-business-name="businessProfile.business.name"
						:data-business-nicename="businessProfile.business.nicename"
						:data-category-name="category.nicename"
						:data-no-partner-link="true"
						class="site-cta sm-directlink"
						><slot></slot
					></a>
					<a
						v-else-if="businessProfile.website_track"
						:onclick="
							'openVisitSiteLightbox(event, \'' +
							businessProfile.business.name +
							'\', \'' +
							businessProfile.category.name +
							'\', \'' +
							businessProfile.category.nicename +
							'\');'
						"
						:id="'link_' + businessProfile.business.nicename"
						rel="nofollow"
						:href="businessProfile.website_track"
						target="_blank"
						:data-business-name="businessProfile.business.name"
						:data-business-nicename="businessProfile.business.nicename"
						:data-category-name="category.nicename"
						:data-no-partner-link="true"
						class="site-cta"
						><slot></slot
					></a>
					<a
						v-else
						:onclick="
							'openVisitSiteLightbox(event, \'' +
							businessProfile.business.name +
							'\', \'' +
							businessProfile.category.name +
							'\', \'' +
							businessProfile.category.nicename +
							'\');'
						"
						:id="'link_' + businessProfile.business.nicename"
						rel="nofollow"
						:href="
							['personal-loans'].includes(category.nicename)
								? quoteFunnelUrl + '/' + businessProfile.business.nicename
								: quoteFunnelUrl
						"
						target="_blank"
						:data-business-name="businessProfile.business.name"
						:data-business-nicename="businessProfile.business.nicename"
						:data-category-name="category.nicename"
						:data-no-partner-link="true"
						class="site-cta"
						><slot></slot
					></a>
					<a
						:id="'link_' + businessProfile.business.nicename + '_oe'"
						rel="nofollow"
						:href="
							['personal-loans'].includes(category.nicename)
								? quoteFunnelUrl + '/' + businessProfile.business.nicename
								: quoteFunnelUrl
						"
						target="_blank"
						class="hide sm-cta site-cta offer-engine-link"
						>{{ ctaButtonText }}</a
					>
				</template>
				<template
					v-else-if="businessProfile.monetized_b && !businessProfile.api_partner && businessProfile.website_track"
				>
					<a
						v-if="urlParams && urlParams.directlink"
						:id="'link_' + businessProfile.business.nicename"
						rel="nofollow"
						:href="businessProfile.website_track"
						target="_blank"
						:data-business-name="businessProfile.business.name"
						:data-business-nicename="businessProfile.business.nicename"
						:data-category-name="category.nicename"
						class="site-cta sm-directlink"
						><slot></slot
					></a>
					<a
						v-else
						:onclick="
							'openVisitSiteLightbox(event, \'' +
							businessProfile.business.name +
							'\', \'' +
							businessProfile.category.name +
							'\', \'' +
							businessProfile.category.nicename +
							'\');'
						"
						:id="'link_' + businessProfile.business.nicename"
						rel="nofollow"
						:href="businessProfile.website_track"
						target="_blank"
						:data-business-name="businessProfile.business.name"
						:data-business-nicename="businessProfile.business.nicename"
						:data-category-name="category.nicename"
						class="site-cta"
						><slot></slot
					></a>
					<a
						:id="'link_' + businessProfile.business.nicename + '_oe'"
						rel="nofollow"
						:href="quoteFunnelUrl"
						target="_blank"
						class="hide sm-cta site-cta offer-engine-link"
						>{{ ctaButtonText }}</a
					>
				</template>
				<template v-else>
					<a
						:href="`/reviews/${businessProfile.category.nicename}/${businessProfile.business.nicename}`"
						target="_blank"
						:data-business-name="businessProfile.business.name"
						:data-category-name="category.nicename"
						><slot></slot
					></a>
				</template>
			</template>
			<template v-else>
				<template v-if="businessProfile.monetized_b && businessProfile.website_track">
					<a
						v-if="urlParams && urlParams.directlink"
						rel="nofollow"
						class="site-cta sm-directlink"
						target="_blank"
						:href="businessProfile.website_track"
						:data-business-name="businessProfile.business.name"
						:data-business-nicename="businessProfile.business.nicename"
						:data-category-name="category.nicename"
						><slot></slot
					></a>
					<a
						v-else-if="['checking-accounts', 'personal-credit-cards'].includes(category.nicename)"
						rel="nofollow"
						class="site-cta sm-directlink"
						:onclick="'openQualifier(event, this)'"
						:href="businessProfile.website_track"
						:data-business-name="businessProfile.business.name"
						:data-business-nicename="businessProfile.business.nicename"
						:data-category-name="category.nicename"
						><slot></slot
					></a>
					<a
						v-else
						rel="nofollow"
						class="site-cta"
						target="_blank"
						:href="
							stickyProductCta == true
								? businessProfile.website_track + '&adv_sub3=exp-best-sticky-cta'
								: businessProfile.website_track
						"
						:data-business-name="businessProfile.business.name"
						:data-business-nicename="businessProfile.business.nicename"
						:data-category-name="category.nicename"
						><slot></slot
					></a>
				</template>
				<template v-else>
					<a
						:href="`/reviews/${businessProfile.category.nicename}/${businessProfile.business.nicename}`"
						target="_blank"
						:data-business-name="businessProfile.business.name"
						:data-category-name="category.nicename"
						><slot></slot
					></a>
				</template>
			</template>
		</template>
	</span>
</template>

<script>
import { getQueryParams } from "./../../helpers/index.js";
import settings from "../../data/settings.js";
import { getQuoteFunnelUrl } from "../../helpers";

// Logic handler for BusinessImage and BusinessName; used in Bestof, Localized; insert content using slot
export default {
	name: "BusinessLink",
	props: {
		businessProfile: Object,
		supportedVerticals: Object,
		category: Object,
		vcategory: Object,
		stickyProductCta: Boolean,
		hideCta: Boolean,
		urlParams: Object,
	},
	data() {
		return {
			offerId: null,
			affId: null,
			buttonText: "Visit Site",
			ctaButtonText: "Get Personalized Rates",
			quoteFunnelUrl: "",
		};
	},
	created() {
		this.getQuoteFunnelUrl();
		if (this.category.OfferEngine) {
			this.ctaButtonText = this.category.OfferEngine.ctaButtonText;
		}
		this.buttonText = this.businessProfile.buttonText;
		if (this.businessProfile.website_track) {
			let qp = getQueryParams(this.businessProfile.website_track);
			this.offerId = qp.offer_id;
			if (this.offerId) {
				this.affId = qp.aff_id ? qp.aff_id : "2";
			}
		}
	},
	methods: {
		leadCaptureClick(event) {
			leadCaptureClick(event.target);
		},
		getQuoteFunnelUrl() {
			this.quoteFunnelUrl = getQuoteFunnelUrl(
				this.category,
				this.vcategory,
				`${settings.httpProt}${settings.wwwDomain}`
			);
		},
	},
};
</script>
