<template>
	<span>
		<business-link
			:businessProfile="businessProfile"
			:supportedVerticals="supportedVerticals"
			:category="category"
			:vcategory="vcategory"
			:stickyProductCta="stickyProductCta"
			:hideCta="hideCta"
			:urlParams="urlParams"
			>{{ businessProfile.business.name }}</business-link
		>
	</span>
</template>

<script>
import BusinessLink from "./BusinessLink.vue";

// Used in Bestof, Localized
export default {
	name: "BusinessName",
	components: {
		BusinessLink,
	},
	props: {
		businessProfile: Object,
		supportedVerticals: Object,
		category: Object,
		vcategory: Object,
		stickyProductCta: Boolean,
		hideCta: Boolean,
		urlParams: Object,
	},
};
</script>
