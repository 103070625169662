<template>
	<div class="search-result" :class="{ 'item-selected': selected }">
		<a v-if="link" :href="link" v-on:focus="select" v-on:blur="deselect">
			<div class="icon-wrapper" v-if="icon">
				<img :src="icon" class="result-icon" alt />
			</div>
			<span
				v-html="this.$options.filters.highlightAll(pageTitle, searchTerms) + generateSuffix()"
				:title="this.$options.filters.stripHtmlContent(pageTitle)"
				class="result-text"
			></span>
		</a>
		<span v-if="!link">
			<div class="icon-wrapper" v-if="icon">
				<img :src="icon" class="result-icon" alt />
			</div>
			<span
				v-html="this.$options.filters.highlightAll(pageTitle, searchTerms) + generateSuffix()"
				:title="this.$options.filters.stripHtmlContent(pageTitle)"
				class="result-text"
			></span>
		</span>
	</div>
</template>

<script>
import { highlightAll, stripHtmlContent } from "../../../../common/src/helpers";
export default {
	name: "SearchResult",
	props: ["pageTitle", "icon", "link", "searchTerms", "selected", "suffix"],
	data() {
		return {};
	},
	filters: {
		highlightAll,
		stripHtmlContent,
	},
	methods: {
		select() {
			this.selected = true;
		},
		deselect() {
			this.selected = false;
		},
		generateSuffix() {
			if (this.suffix) {
				return `<span class="suffix">${this.suffix}</span>`;
			}
			return "";
		},
	},
};
</script>

<style lang="scss">
.search-result {
	padding: 4px 15px;
	&:hover,
	&.item-selected {
		background-color: rgba(6, 169, 219, 0.1);
	}
	a {
		color: inherit;
		width: 100%;
		display: inline-block;
	}
	.icon-wrapper {
		display: inline-block;
		width: 30px;
		height: 30px;
		margin-right: 14px;
		vertical-align: bottom;
		.result-icon {
			margin: 0 auto;
			max-width: 100%;
			max-height: 100%;
			position: relative;
			top: 50%;
			transform: translateY(-50%);
		}
	}
	span.result-text {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		width: calc(100% - 44px);
		display: inline-block;
		vertical-align: middle;
		span.suffix {
			color: var(--sm-light-gray);
			font-size: 90%;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			&:before {
				content: "\2022";
				margin: 0 6px;
			}
		}
	}
}
</style>
