<template>
	<div class="testimonials-container">
		<div class="testimonials">
			<h2>Don't just take our word for it.</h2>
			<p>Here’s what SuperMoney community members are saying about their tax relief experience.</p>
			<div class="testimonials-main">
				<transition name="fade">
					<div v-show="currentIndex === 0" class="testimonial">
						<div class="customer">
							<h3 class="name">Melissa</h3>
							<p class="highlight">"They will help you..."</p>
						</div>
						<blockquote>
							<p>
								Thanks again so much for going the extra mile on my case I was really worried about how I was going to
								finally get my taxes in order, there was some pressing issues I wasn't sure I was going to be able to
								get handled...
								<strong>you really came through for me when they didn’t have to that really means a lot to</strong>
								someone like me who doesn't have a lot of disposable income. I knew I called the right tax people when I
								got off the phone with the first representative they assured me I was doing the right thing by calling
								them and now I have proof I did the right thing, <strong>Thank You again.</strong>
							</p>
						</blockquote>
					</div>
				</transition>
				<transition name="fade">
					<div v-show="currentIndex === 1" class="testimonial">
						<div class="customer">
							<h3 class="name">Emmanuel D.</h3>
							<p class="highlight">"I am happy to recommend..."</p>
						</div>
						<blockquote>
							<p>
								I recently took over my retired parents' business. After only a couple months on the job, I received a
								notice from the IRS saying they owed $23,000 in taxes. I had no experience with self-employed taxes or
								dealing with the IRS, so I had no idea what to do. After some searching and I called Optima and was
								connected to someone right away. Over the course of a few months I was able to get an offer and
								compromise on the tax liability, which ended up saving my parents over $15,000. The rest is on an
								installment plan they can now afford. Throughout my experience was that Optima was professional,
								competent and informed. I am happy to recommend you to anyone who finds themselves in a similar
								situation.
							</p>
						</blockquote>
					</div>
				</transition>
				<transition name="fade">
					<div v-show="currentIndex === 2" class="testimonial">
						<div class="customer">
							<h3 class="name">Charlotte J.</h3>
							<p class="highlight">"They immediately took control..."</p>
						</div>
						<blockquote>
							<p>
								I am a small business owner and have never had any issues with my taxes until now. Suddenly, I owe a
								bunch of money I have never had to pay before and my income has not changed. I went to some local tax
								offices and received conflicting information that confused me more... They immediately took control and
								explained the situation in an understandable way. They squared everything away with the IRS and the new
								charges went away overnight! The woman I spoke with was very concerned for my situation and was very
								helpful in answering my questions. I would definitely recommend to all my friends and family or anyone
								looking for a hassle free tax experience. The company even offered me tips and information on what to do
								if something like this happens again or the IRS takes funds from my account. Very helpful!
							</p>
						</blockquote>
					</div>
				</transition>
				<transition name="fade">
					<div v-show="currentIndex === 3" class="testimonial">
						<div class="customer">
							<h3 class="name">Keith W.</h3>
							<p class="highlight">"Great all in one source"</p>
						</div>
						<blockquote>
							<p>
								SuperMoney was great at helping me finagle through a market crowded with a lot of money services. It all
								became so incredibly confusing. They provided a lot of information the helped me make the best decision
								possible for me and my family. I will for sure refer back to them again!
							</p>
						</blockquote>
					</div>
				</transition>
			</div>
			<div class="steps">
				<div class="dot" :class="{ active: currentIndex === 0 }" v-on:click="slide(0)"></div>
				<div class="dot" :class="{ active: currentIndex === 1 }" v-on:click="slide(1)"></div>
				<div class="dot" :class="{ active: currentIndex === 2 }" v-on:click="slide(2)"></div>
				<div class="dot" :class="{ active: currentIndex === 3 }" v-on:click="slide(3)"></div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Testimonials",
	data() {
		return {
			currentIndex: 0,
			manualSlide: false,
		};
	},
	mounted() {
		setTimeout(this.updateIndex, 5000);
	},
	methods: {
		updateIndex() {
			if (this.manualSlide) {
				this.manualSlide = false;
			} else {
				this.currentIndex = this.currentIndex >= 3 ? 0 : this.currentIndex + 1;
			}
			setTimeout(this.updateIndex, 5000);
			return;
		},
		slide(index) {
			this.manualSlide = true;
			this.currentIndex = index;
		},
	},
};
</script>

<style lang="scss">
.testimonials-container {
	margin: 0px 15px;
	.testimonials {
		max-width: 650px;
		margin: auto;

		h2 {
			margin-top: 0.5em;
			font-size: 30px;
		}

		p {
			line-height: 1.4;
			font-size: 20px;
		}
		.fade-enter-active,
		.fade-leave-active {
			transition: 0.5s;
		}
		.fade-enter,
		.fade-leave-to {
			opacity: 0;
		}
		.fade-leave,
		.fade-enter-to {
			opacity: 1;
		}

		.testimonial {
			margin-right: -100%;
			float: left;
			position: relative;
			width: 100%;
		}

		.steps {
			margin-top: 0.75em;
			display: inline-block;
			.dot {
				width: 10px;
				height: 10px;
				background-color: rgba(0, 0, 0, 0.2);
				display: inline-block;
				margin: 0px 6px 0px 6px;
				border-radius: 50%;
				padding: 0px;
				border-width: 2px;
				border: 2px solid #4e4e4e;
				cursor: pointer;

				&.active {
					background-color: var(--accentColor, var(--sm-blue));
					border-color: var(--accentColor, var(--sm-blue));
					border-width: 2px;
					border-style: outset;
				}
			}
		}

		.testimonials-main {
			float: left;
			background-color: #fff;
			border: 1px solid rgba(0, 0, 0, 0.1);
			border-radius: 8px;
			padding: 1em;
			margin-top: 1.5em;

			h3 {
				text-align: left;
				margin: 4px 0 0 0;
			}

			p.highlight {
				text-align: left;
				font-size: 18px;
			}

			blockquote {
				position: relative;
				margin: 20px 0 5px;
				font-size: 16px;
				color: #4e4e4e;
				text-align: left;
				padding: 0 15px;
				border-left: 3px solid rgba(0, 0, 0, 0.25);

				p {
					font-style: normal;
				}

				&:before {
					position: absolute;
					left: -10px;
					top: -3px;
					background: #fff;
					content: "\f10d";
					font-family: FontAwesome;
					color: var(--accentColor, var(--sm-blue));
					font-size: 16px;
					line-height: 1.8;
					margin-left: 0;
				}
			}
		}
	}
	@media (min-width: 768px) {
		margin: 0 15px;
		.testimonials {
			h2 {
				font-size: 34px;
			}
			.testimonials-main {
				padding: 1.5em;
				min-height: 375px;

				blockquote {
					margin: 0 10px;
				}
			}
		}
	}
}
</style>
