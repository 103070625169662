<template>
	<div class="sticky-cta-container" v-show="block && !dismissed">
		<transition name="fade">
			<div
				:class="category.nicename + ' sticky-cta'"
				v-if="block && !dismissed && (category.lead_capture || category.OfferEngine)"
			>
				<div v-if="category.lead_capture && category.OfferEngine">
					<img src="/includes/img/common/compare-rates.svg" alt="Compare Rates" class="cta-img pull-left" />
					<div class="pull-left">
						<h5>{{ category.OfferEngine.ctaTitle }}</h5>
						<p>{{ category.OfferEngine.ctaDescription }}</p>
					</div>
					<a
						class="btn btn-cta pull-right"
						rel="nofollow"
						href="#"
						onclick="event.preventDefault(); leadCaptureClick(this);"
						:data-category-name="category.nicename"
						>{{ category.OfferEngine.ctaButtonText }}</a
					>
				</div>
				<div v-else-if="category.OfferEngine">
					<img src="/includes/img/common/compare-rates.svg" alt="Compare Rates" class="cta-img pull-left" />
					<div class="pull-left">
						<h5>{{ category.OfferEngine.ctaTitle }}</h5>
						<p>{{ category.OfferEngine.ctaDescription }}</p>
					</div>
					<a
						class="btn btn-cta pull-right <?= ($offerEngine['type'] == 'link') ? 'link-sticky-cta' : (($offerEngine['type'] == 'cta') ? 'sticky-listings-cta' : '') ?>"
						:href="quoteFunnelUrl"
						:data-category-name="category.nicename"
						>{{ category.OfferEngine.ctaButtonText }}</a
					>
				</div>
				<div class="dismiss-sticky-cta">
					<a v-if="category.lead_capture" href="" class="close-cta" @click.stop.prevent="dismissCta"
						><u><b>x</b> I'm not looking for free quotes right now</u></a
					>
					<a v-else href="" class="close-cta" @click.stop.prevent="dismissCta">
						<b>x</b> <u>I'm not looking for offers right now</u></a
					>
				</div>
			</div>
			<div
				:class="
					showOrangeStickyCTABottom ? category.nicename + ' sticky-cta sticky-product position-bottom orange-btn' : ''
				"
				v-else-if="showOrangeStickyCTABottom && topProduct"
			>
				<div class="sticky-product-content">
					<div class="inner-content">
						<business-image
							class="business-img"
							imageType="thumb"
							entityType="businesses"
							:business="topProduct.business"
							:parent="topProduct.parent"
							:businessProfile="topProduct"
							:category="category"
							:webpEnabled="webpEnabled"
							:supportedVerticals="supportedVerticals"
							:stickyProductCta="true"
							:hideCta="topProduct.hideCta"
						></business-image>
						<div class="product-info">
							<div class="product-name-wrapper">
								<business-name
									class="product-name"
									:businessProfile="topProduct"
									:category="category"
									:supportedVerticals="supportedVerticals"
									:stickyProductCta="true"
								></business-name>
								<span class="best-picks-icon"
									><img src="/includes/img/bestof/sm-best-badge-xs-yellow.svg" alt="Best Pick"
								/></span>
							</div>
							<profile-nps
								class="profile-nps"
								:data="topProduct"
								:link="'/reviews/' + category.nicename + '/' + topProduct.business.nicename"
							></profile-nps>
						</div>
						<template v-if="topProduct.monetized_b">
							<visit-site-btn
								class="visit-site-btn"
								:businessProfile="topProduct"
								:supportedVerticals="supportedVerticals"
								:category="category"
								:stickyProductCta="true"
								:hideCta="topProduct.hideCta"
							></visit-site-btn>
						</template>
						<template v-else>
							<a
								class="btn btn-primary visit-site-btn"
								:href="'/reviews/' + category.nicename + '/' + topProduct.business.nicename"
								>View Profile</a
							>
						</template>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import { mapState } from "vuex";
import BusinessImage from "./../business/BusinessImage.vue";
import ProfileNps from "./ProfileNps.vue";
import BusinessName from "./../business/BusinessName.vue";
import VisitSiteBtn from "./../VisitSiteBtn.vue";
import settings from "./../../data/settings.js";
import { getQuoteFunnelUrl } from "../../helpers";
// Used in Bestof
export default {
	name: "StickyCta",
	props: {
		category: Object,
		vcategory: Object,
		products: Array,
		supportedVerticals: Object,
		webpEnabled: Boolean,
	},
	components: {
		BusinessImage,
		ProfileNps,
		BusinessName,
		VisitSiteBtn,
	},
	data() {
		return {
			dismissed: false,
			block: false,
			stickyProductCTADisplayed: false,
			topProduct: {},
			quoteFunnelUrl: "",
		};
	},
	computed: {
		showOrangeStickyCTABottom() {
			return this.block && !this.dismissed;
		},
		...mapState({
			experiment: (state) => state.bestofStore.experiment,
			experimentID: (state) => state.bestofStore.experimentID,
		}),
	},
	created() {
		window.addEventListener("scroll", this.stickyScroll);
		window.addEventListener("resize", this.stickyScroll);
	},
	updated() {
		if (!this.dismissed) {
			this.getQuoteFunnelUrl();
		}
		if (this.category && sessionStorage.getItem(this.category.nicename + "-sticky-cta-dismissed") == "true") {
			this.dismissed = true;
		}
		this.$nextTick(function () {
			if (this.showOrangeStickyCTABottom == true) {
				$.supermoney.initCtaLinks();
			}
		});
	},
	methods: {
		stickyScroll(e) {
			let windowTopPosition = window.scrollY - 90;
			let triggerContainers = document.getElementsByClassName("additional-content");
			let triggerContainer, top;
			if (!triggerContainers.length) {
				triggerContainer = document.getElementById("article");
				top = triggerContainer.offsetTop + 90;
			} else {
				triggerContainer = triggerContainers[0];
				top = triggerContainer.offsetTop + 90;
			}

			if (window.innerWidth <= 768) {
				let topElements = document.getElementsByClassName("bestof-vertical-profile");
				if (!topElements.length) {
					topElements = document.getElementsByClassName("results-table");
				}

				top = topElements[0].offsetTop + topElements[0].offsetHeight + 45;
			}
			let bottom = triggerContainer.offsetTop + triggerContainer.offsetHeight;

			let stickyCtaElements = document.getElementsByClassName("sticky-cta-container");
			let stickyCta = stickyCtaElements[stickyCtaElements.length - 1];
			let stickyHeight = stickyCta;
			if (stickyHeight) {
				stickyHeight = stickyHeight.offsetHeight;
			} else {
				return;
			}

			if (windowTopPosition > top && Object.keys(bestOfData.topProduct).length) {
				this.topProduct = bestOfData.topProduct;
				if (windowTopPosition - stickyHeight < bottom) {
					// make sticky
					this.block = true;
					stickyCta.style.top = "45px";
				} else {
					// set position to value
					stickyCta.style.top = bottom - stickyHeight - 45 + "px";
					this.block = false;
				}
			} else if (windowTopPosition <= top) {
				// restore position
				stickyCta.style.top = "unset";
				this.block = false;
			}
		},
		dismissCta() {
			sessionStorage.setItem(this.category.nicename + "-sticky-cta-dismissed", "true");
			this.dismissed = true;
		},
		getQuoteFunnelUrl() {
			this.quoteFunnelUrl = getQuoteFunnelUrl(
				this.category,
				this.vcategory,
				`${settings.httpProt}${settings.wwwDomain}`
			);
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.sticky-cta-container {
	.fade-enter-active,
	.fade-leave-active {
		transition: 0.5s;
	}
	.fade-enter,
	.fade-leave-to {
		opacity: 0;
	}
	.fade-leave,
	.fade-enter-to {
		opacity: 1;
	}
	.sticky-cta {
		display: block;
		position: fixed;
		top: 45px;
		background: #ecf9ff;
		box-sizing: border-box;
		padding: 0.75em 1em 0.5em;
		width: 100%;
		border-bottom: 1.5px solid rgba(0, 0, 0, 0.08);
		text-align: center;

		.pull-left,
		.pull-right {
			float: unset;
		}
		.pull-left {
			text-align: center;

			h5 {
				margin: 0;
			}
			p {
				font-size: 0.8em;
				margin-bottom: 0;
				// text-align: center;
			}
		}
		.btn-cta {
			padding: 0.5em 1.2em;
			font-size: 1em;
			margin: 0.5em 0;
		}

		.dismiss-sticky-cta {
			display: block;
		}

		.close-cta {
			color: rgb(163, 163, 163);
			font-size: 12px;
			margin-left: 5px;

			b {
				margin-right: 0.1em;
			}
		}

		.cta-img {
			display: none;
			height: 40px;
			margin-right: 20px;
		}
	}
}
@media (min-width: 768px) {
	.sticky-cta-container {
		.sticky-cta {
			.dismiss-sticky-cta {
				display: none;
			}

			.pull-left {
				float: left;
				text-align: left;
			}
			.pull-right {
				float: right;
			}

			.cta-img {
				display: block;
			}
			.btn-cta {
				margin: 0;
			}
		}
	}
}
</style>
