<template>
	<div class="recommendation-score">
		<div class="reviews-aggregation-score-widget">
			<div class="recommendation-section">
				<p class="viz-label">SUPERMONEY NET RECOMMENDATION SCORE</p>
				<div class="biz-rec-score-container">
					<p
						:class="
							'reviews-aggregation-score-widget-score'.concat(data.nps_rating > 0 ? ' right-align' : ' left-align')
						"
						:style="data.nps_rating > 0 ? { right: fromRight + '%' } : { left: fromLeft + '%' }"
					>
						<span v-if="data.nps_rating > 0">+{{ data.nps_rating }}</span>
						<span v-else>{{ data.nps_rating }}</span>
					</p>
				</div>
				<div class="net-recommendation-score-viz" aria-hidden="true">
					<div class="recommendation-range-bar"></div>
					<div class="score-range-labels">
						<span class="negative">-100</span>
						<span class="neutral">0</span>
						<span class="positive">+100</span>
					</div>
					<div class="plain-terms">
						<span class="not-rec"> <i class="fa fa-flip-horizontal fa-thumbs-o-down"></i> No one recommends </span>
						<span class="rec">
							Everyone recommends
							<i class="fa fa-thumbs-o-up"></i>
						</span>
					</div>
				</div>
				<p>
					Recommendation score measures the loyalty between a provider and a consumer. It's at 100% if everybody
					recommends the provider, and at -100% when no one recommends.
				</p>
			</div>
			<div v-if="highlightsFlag" class="top-features">
				<dl>
					<div class="feature-flex" v-for="feature in data.topFeatures" :key="feature">
						<dt class="feature-name">{{ feature.feature_name }}</dt>
						<dd class="feature-data">
							<feature-data
								:feature="{ feature: feature, feature_id: feature['id'] }"
								:data="data"
								:index="index"
							></feature-data>
						</dd>
					</div>
				</dl>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "RecommendationScore",
	props: {
		data: Array,
		highlightsFlag: Boolean,
	},
	components: {
		FeatureData: () => import("./../business/FeatureData.vue"),
	},
	data() {
		return {
			fromLeft: ((this.data.nps_rating + 100) / 200) * 100,
			fromRight: 100 - ((this.data.nps_rating + 100) / 200) * 100,
		};
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.reviews-aggregation-score-widget {
	max-width: 400px;
	width: 100%;
	min-height: 0;
	float: right;
	background-color: #fdfdfd;
	padding: 1em;
	border-radius: 3px;
	margin-top: 0;
	text-align: left;

	dl {
		width: 100%;
		margin-bottom: 0;
	}
	.top-features {
		margin-top: 1.5em;

		.feature-flex {
			display: flex;
			align-items: flex-start;
			margin-bottom: 0.5em;

			.feature-name {
				font-size: 0.85em;
				font-weight: 600;
				display: inline-block;
				width: 55%;
				margin-right: 0.25em;
			}
			.feature-data {
				margin-left: 0.25em;
				display: inline-block;
				font-size: 0.85em;
			}
		}
	}

	.viz-label {
		color: #444;
		border-bottom: unset;
		letter-spacing: 1px;
		margin: 0 0 15px;
		line-height: 1.4;
		font-size: 1em;
		text-align: left;
		font-weight: bold;
	}
	.biz-rec-score-container {
		position: relative;
		display: inline-block;
		width: 100%;
		height: 40px;
	}
	.reviews-aggregation-score-widget-score {
		position: absolute;
		padding: 0 12px;
		&.left-align:before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 4px;
			height: 70px;
			border-radius: 20px;
			background-color: #444;
			box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.08);
		}
		&.right-align {
			text-align: right;
			&:before {
				content: "";
				position: absolute;
				right: 0;
				top: 0;
				width: 4px;
				height: 70px;
				border-radius: 20px;
				background-color: #444;
				box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.08);
			}
		}
	}
	p {
		line-height: 1.4;
		color: var(--sm-dark-gray);
		margin-bottom: 0;
		font-size: 12px;
		&.reviews-aggregation-score-widget-score {
			font-size: 30px;
			color: var(--sm-black);
			line-height: 1;
			font-weight: bold;
			margin: 0 0 0.5em;
			z-index: 11;
		}
	}
}
.net-recommendation-score-viz {
	.recommendation-range-bar {
		width: 100%;
		height: 20px;
		background: linear-gradient(to right, var(--sm-negative-rating) 0, #f9f9f9 50%, var(--sm-positive) 100%);
		margin-bottom: 10px;
		position: relative;
		z-index: 10;
		&:before {
			content: "";
			position: absolute;
			width: 2px;
			height: 20px;
			left: calc(50% - 1px);
			background-color: rgba(255, 255, 255, 1);
		}
	}

	.score-range-labels {
		width: 100%;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		font-size: 13px;
		span {
			flex: 1;
			color: #717171;
			&.negative {
				text-align: left;
			}
			&.neutral {
				text-align: center;
			}
			&.positive {
				text-align: right;
			}
		}
	}
	.plain-terms {
		width: 100%;
		margin-bottom: 10px;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		span {
			flex: 1;
			font-size: 1em;
			line-height: 1.4;
			font-weight: bold;
			&.not-rec {
				color: var(--sm-negative-rating);
				text-align: left;
			}
			&.rec {
				color: var(--sm-positive);
				text-align: right;
			}
		}
	}
}

@media (min-width: 768px) {
	.reviews-aggregation-score-widget {
		display: flex;
		max-width: 800px;

		.recommendation-section {
			display: inline-block;
			max-width: 400px;
		}
		.top-features {
			width: 45%;
			display: inline-block;
			max-width: 400px;
			margin-left: 3em;

			.feature-flex {
				display: flex;
				align-items: flex-start;
				margin-bottom: 0.5em;

				.feature-name {
					font-size: 0.85em;
					font-weight: 600;
					display: inline-block;
					width: 55%;
					margin-right: 0.25em;
				}
				.feature-data {
					margin-left: 0.25em;
					display: inline-block;
					font-size: 0.85em;
				}
			}
		}
	}
}
</style>
