<template>
	<div class="bb-loader">
		<div></div>
		<div></div>
		<div></div>
		<div></div>
	</div>
</template>

<script>
export default {
	name: "BouncingBallLoader",
};
</script>

<style lang="scss">
.bb-loader {
	margin-top: 10px;
	vertical-align: bottom;
	display: inline-block;
	> div {
		display: inline-block;
		background-color: var(--accentColor, var(--sm-blue));
		height: 7px;
		width: 7px;
		border-radius: 50%;
		animation-name: bounce;
		animation-duration: 1.1s;
		animation-iteration-count: infinite;
		margin-right: 0.1em;
	}
	> div:nth-child(2) {
		animation-delay: 0.1s;
	}
	> div:nth-child(3) {
		animation-delay: 0.2s;
	}
	> div:nth-child(4) {
		animation-delay: 0.3s;
	}
}
@-webkit-keyframes bounce {
	0% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(-13px);
	}
}
</style>
