const state = {
	isLoading: false, // are we in a loading state?
	modalVisible: false, // is modal visible?
	modalTitle: "", // heading/title for the modal
	modalSubtitle: "", // subtitle for modal
	modalComponent: null, // componentName to load in the modal
	modalAdditionalContainerClass: "", // any additional classes to append to the
	modalMode: "", // generic variable to specify a mode for a modal component
	modalId: 0, // generic variable to hold any ID needed by a modal component
	closeButton: true, // show close button?
	featureTypes: {
		// feature full names used in numerous components
		boolean: "Boolean",
		decimal: "Decimal",
		handlebars: "Handlebars",
		json: "JSON",
		integer: "Integer",
		"select-multi": "Multi-Select",
		"select-one": "Single-Select",
		text: "Text",
		url: "URL",
	},
	textFeatureTypes: [
		// text-based feature types
		"handlebars",
		"json",
		"text",
		"url",
	],
	numberFeatureTypes: [
		// number-based feature types
		"decimal",
		"integer",
	],
};

// actions
const actions = {
	setIsLoading({ commit }, isLoading) {
		commit("setIsLoading", isLoading);
	},
	setModalTitle({ commit }, modalTitle) {
		commit("setModalTitle", modalTitle);
	},
	setModalSubtitle({ commit }, modalSubtitle) {
		commit("setModalSubtitle", modalSubtitle);
	},
	setModalVisible({ commit }, modalVisible) {
		commit("setModalVisible", modalVisible);
	},
	setModalComponent({ commit }, modalComponent) {
		commit("setModalComponent", modalComponent);
	},
	setModalMode({ commit }, modalMode) {
		commit("setModalMode", modalMode);
	},
	setModalId({ commit }, modalId) {
		commit("setModalId", modalId);
	},
	setModalCloseButton({ commit }, closeButton) {
		commit("setModalCloseButton", closeButton);
	},
	setModalAdditionalContainerClass({ commit }, additionalContainerClass) {
		commit("setModalAdditionalContainerClass", additionalContainerClass);
	},
};

// mutations
const mutations = {
	setIsLoading(state, isLoading) {
		state.isLoading = isLoading;
	},
	setModalTitle(state, modalTitle) {
		state.modalTitle = modalTitle;
	},
	setModalSubtitle(state, modalSubtitle) {
		state.modalSubtitle = modalSubtitle;
	},
	setModalVisible(state, modalVisible) {
		state.modalVisible = modalVisible;
	},
	setModalComponent(state, modalComponent) {
		state.modalComponent = modalComponent;
	},
	setModalMode(state, modalMode) {
		state.modalMode = modalMode;
	},
	setModalId(state, modalId) {
		state.modalId = modalId;
	},
	setModalCloseButton(state, closeButton) {
		state.closeButton = closeButton;
	},
	setModalAdditionalContainerClass(state, additionalContainerClass) {
		state.modalAdditionalContainerClass = additionalContainerClass;
	},
};

// initialize
export default {
	namespaced: true,
	state,
	actions,
	mutations,
};
