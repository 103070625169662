<template>
	<div :class="classNames" v-on:keyup.prevent="keyboardHandler" v-on:keydown.down.prevent v-click-outside="closeSearch">
		<div
			class="search-icon"
			:class="{ 'show-mobile': searchType == 'header', hidden: searchType != 'header' }"
			@click="toggleForm()"
			aria-label="open search"
			role="button"
		>
			<i class="fa fa-search"></i>
		</div>
		<div class="sm-search-form" :class="{ 'hide-mobile': searchType == 'header' }">
			<i class="fa fa-search search-input-icon" aria-hidden="true"></i>
			<input
				v-model="searchTerms"
				ref="search"
				@focus="active = true"
				class="search-input"
				:placeholder="placeholderText"
				aria-label="Search on SuperMoney"
				aria-labelledby="searchResultsSection"
			/>
			<div v-if="working && active && !error" class="search-working">
				<bouncing-ball-loader></bouncing-ball-loader>
			</div>
			<span
				class="search-input-close"
				:class="{ 'show-mobile': searchType == 'header', hidden: searchType != 'header' }"
				@click="toggleForm()"
				>✕</span
			>
		</div>
		<div class="search-results-wrapper" v-on:blur="closeSearch">
			<div v-if="results.length > 0 && active && !error" class="search-results" id="search-results">
				<template v-for="(type, index) in documentTypes">
					<search-results-section
						v-if="type.results.length > 0"
						:key="type.title"
						:title="type.title"
						:results="type.results"
						:searchTerms="searchTerms"
						class="result-category"
						:active="selectedSection == index"
						:selectedItem="selectedSectionItem"
					></search-results-section>
				</template>
			</div>
			<div
				v-if="results.length == 0 && active && !noResults && !error && searchType == 'header'"
				class="search-results"
			>
				<search-results-section
					:title="'Common Searches'"
					:results="suggestions"
					:active="selectedSection == 'common'"
					:selectedItem="selectedSectionItem"
				></search-results-section>
			</div>
			<div v-if="results.length == 0 && active && noResults && !error" class="search-results search-no-results">
				<search-result :pageTitle="'No results found'"></search-result>
			</div>
			<div v-if="active && error" class="search-results search-error">
				<search-result :pageTitle="'Search error'"></search-result>
			</div>
		</div>
	</div>
</template>

<script>
import vClickOutside from "v-click-outside";
import BouncingBallLoader from "../../../../common/src/components/BouncingBallLoader.vue";
import settings from "../../../../common/src/data/settings.js";
import SearchApi from "../helpers/SearchApi.js";
import SearchResult from "./SearchResult.vue";
import SearchResultsSection from "./SearchResultsSection.vue";

export default {
	name: "SearchBox",
	props: {
		resultsMax: {
			type: Number,
			default: null,
		},
		searchType: {
			type: String,
			default: "header",
		},
		placeholderText: {
			type: String,
			default: "What are you looking for?",
		},
		searchCategories: {
			type: Array,
			default: null,
		},
	},
	components: {
		SearchResultsSection,
		SearchResult,
		BouncingBallLoader,
	},
	directives: {
		clickOutside: vClickOutside.directive,
	},
	data() {
		return {
			showSearchBox: false,
			searchTerms: null,
			active: false,
			noResults: false,
			error: false,
			working: false,
			debounceDelay: 500,
			debounceTimeout: null,
			gtmTimeout: null,
			searchRequest: null,
			selectedItem: false, // global index of selected item
			selectedSection: false, // calculate the selected section
			selectedSectionItem: false, // the selected item inside the selected section
			selectedItemLink: false,
			topResultCutoff: 120,
			documentTypes: {
				top: {
					results: [],
					newResults: [],
				},
				funnel: {
					title: "Request Free Quotes",
					icon: "/includes/img/search/quote-funnels.svg",
					results: [],
					newResults: [],
				},
				"offer-engine": {
					title: "Apply & Prequalify",
					icon: "/includes/img/search/apply-prequalify.svg",
					results: [],
					newResults: [],
				},
				best: {
					title: "Our Best Picks",
					icon: "/includes/img/search/best-picks.svg",
					results: [],
					newResults: [],
				},
				product: {
					title: "Products",
					results: [],
					newResults: [],
				},
				company: {
					title: "Companies",
					results: [],
					newResults: [],
				},
				category: {
					title: "Comparisons",
					icon: "/includes/img/search/comparison-pages.svg",
					results: [],
					newResults: [],
				},
				"expert-guide": {
					title: "Expert Guides",
					icon: "/includes/img/search/expert-guides.svg",
					results: [],
					newResults: [],
				},
				"industry-study": {
					title: "Industry Studies",
					icon: "/includes/img/search/industry-studies.svg",
					results: [],
					newResults: [],
				},
				post: {
					title: "Relevant Articles",
					icon: "/includes/img/search/relevant-articles.svg",
					results: [],
					newResults: [],
				},
				local: {
					title: "Localized Results",
					icon: "/includes/img/search/localized-results.svg",
					results: [],
					newResults: [],
				},
				topic: {
					title: "Topics",
					icon: "/includes/img/search/topics.svg",
					results: [],
					newResults: [],
				},
				page: {
					title: "Other",
					icon: "/includes/img/common/logo-sm-blue.svg",
					results: [],
					newResults: [],
				},
			},
			results: [],
			suggestions: [],
		};
	},
	created() {
		this.suggestions = [
			{
				type: "best",
				path: "/best/personal-loans/",
				title: "Best Personal Loans",
				suffix: this.documentTypes["best"].title,
				icon: this.documentTypes["best"].icon,
			},
			{
				type: "category",
				path: "/reviews/mortgage-refinance",
				title: "Mortgage Refinance",
				suffix: this.documentTypes["category"].title,
				icon: this.documentTypes["category"].icon,
			},
			{
				type: "expert-guide",
				path: "/buy-a-house",
				title: "How to Buy a House",
				suffix: this.documentTypes["expert-guide"].title,
				icon: this.documentTypes["expert-guide"].icon,
			},
			{
				type: "product",
				path: "/reviews/personal-credit-cards/chase-sapphire-preferred-card",
				title: "Chase Sapphire Preferred",
				suffix: this.documentTypes["product"].title,
				icon: `${
					settings.awsCloudFrontURL || settings.awsBucketURL
				}/businesses/1/chase-sapphire-preferred-card-37e284ef351a607bf154e2759c63549d_toe.png`,
			},
			{
				type: "post",
				path: "/how-to-compare-money-transfer-services/",
				title: "Compare Money Transfer Services",
				suffix: this.documentTypes["post"].title,
				icon: this.documentTypes["post"].icon,
			},
		];
		/* this works on mobile but on desktop it removes .dropdown-dim
		on the active watcher after it gets added by the mega-dropdown script
		*/
		if (this.searchType == "header") {
			const _self = this;
			const touchDetect = function () {
				_self.debounceDelay = 1000;
				window.removeEventListener("touchstart", touchDetect);
			};
			window.addEventListener("touchstart", touchDetect);
		}
	},
	watch: {
		searchTerms(val) {
			this.error = false;
			if (this.gtmTimeout) {
				clearTimeout(this.gtmTimeout);
			}
			if (this.debounceTimeout) {
				clearTimeout(this.debounceTimeout);
			}
			if (val.length > 2) {
				const _self = this;
				this.working = true;
				this.debounceTimeout = setTimeout(async () => {
					try {
						const results = await SearchApi.search(val, this.searchCategories);
						//false is a cancellation
						if (results !== false) {
							this.working = false;
							_self.results = results;
							_self.noResults = !results.length;
							this.gtmEvent(val);
						}
					} catch (e) {
						_self.error = true;
						this.working = false;
					}
				}, this.debounceDelay);
			} else if (val.length <= 2) {
				this.results = [];
				this.noResults = false;
				this.working = false;
			}
		},
		results(resultsVal) {
			this.resetItemSelection();
			if (Array.isArray(resultsVal) && resultsVal.length) {
				let val = [...resultsVal];
				let topPickCount = 0,
					maxTopPicks = this.resultsMax ? this.resultsMax : 0;
				if (val.length > 10 && !this.resultsMax) {
					val.sort(function (a, b) {
						return b.score - a.score;
					});
					if (val[0].score > this.topResultCutoff) {
						maxTopPicks = Math.min(Math.floor(val.length / 3), 5);
					}
				} else if (this.searchCategories) {
					val.sort(function (a, b) {
						return b.score - a.score;
					});
				}
				for (let i in val) {
					const result = val[i];
					if (typeof this.documentTypes[result.type] === "undefined") {
						result.type = "post"; // fall back to post type
					}
					if (!result.icon && this.documentTypes[result.type].icon) {
						result.icon = this.documentTypes[result.type].icon;
					}
					if (this.resultsMax) {
						//special handling to fill all the top results. mainly for inline search box
						//sort the results into categories so we can get the top from each category
						result.suffix = this.documentTypes[result.type].title;
						if (!this.searchCategories) {
							this.documentTypes[result.type].newResults.push(result);
						}
						if (topPickCount < maxTopPicks) {
							// fill in top 8 results as fallback
							this.documentTypes["top"].newResults.push(result);
							topPickCount++;
						}
					} else {
						//top pick if above threshold or special rules for product/company
						//product logic is also in API so change both if we need to
						if (
							topPickCount < maxTopPicks &&
							((result.type == "product" && (result.score >= 150 || (result.score >= 120 && result.rank_score > 60))) ||
								(result.type == "company" && result.score >= 150) ||
								(result.type != "product" && result.type != "company" && result.score > this.topResultCutoff))
						) {
							result.suffix = this.documentTypes[result.type].title;
							this.documentTypes["top"].newResults.push(result);
							topPickCount++;
						} else {
							if (this.documentTypes[result.type] && this.documentTypes[result.type].newResults.length < 5) {
								this.documentTypes[result.type].newResults.push(result);
							}
						}
					}
				}
				if (this.resultsMax && !this.searchCategories) {
					//special handling to fill all the top results. mainly for inline search box
					let newTopPickCount = 0,
						categoryTopPicks = [];
					for (let i in this.documentTypes) {
						//get the top result from each category
						if (i !== "top" && this.documentTypes[i].newResults.length) {
							const result = this.documentTypes[i].newResults.shift();
							this.documentTypes["top"].newResults = this.documentTypes["top"].newResults.filter(
								(item, idx) => item.path != result.path
							);
							categoryTopPicks.push(result);
							newTopPickCount++;
							this.documentTypes[i].newResults = [];
						}
					}
					if (newTopPickCount) {
						if (newTopPickCount < this.resultsMax) {
							categoryTopPicks = categoryTopPicks.concat(this.documentTypes["top"].newResults).slice(0, maxTopPicks);
						}
						categoryTopPicks.sort(function (a, b) {
							return b.score - a.score;
						});
						this.documentTypes["top"].newResults = categoryTopPicks;
					}
				}
			}
			for (let cat in this.documentTypes) {
				if (cat == "product") {
					// reset product sorting to by rank_score since sorting by rank_score for top results breaks it
					this.documentTypes[cat].newResults.sort(function (a, b) {
						if (a.oob != b.oob) {
							return a.oob ? -1 : 1;
						}
						if (a.showlisting != b.showlisting) {
							return a.showlisting ? 1 : -1;
						}
						if (a.featured != b.featured) {
							return a.featured ? 1 : -1;
						}
						if (a.monetized != b.monetized) {
							return a.monetized ? 1 : -1;
						}
						return b.rank_score - a.rank_score;
					});
				}
				this.documentTypes[cat].results = this.documentTypes[cat].newResults;
				this.documentTypes[cat].newResults = [];
			}
		},
		active(val) {
			if (this.searchType != "header") {
				return;
			}

			if (val) {
				globalToggleNav(false);
				document.body.classList.add("dropdown-dim-search");
				document.getElementsByTagName("body")[0].style.overflow = "hidden";
				document.getElementsByTagName("body")[0].style.height = "100%";
			} else {
				document.body.classList.remove("dropdown-dim-search");
				document.getElementsByTagName("body")[0].style.overflow = "auto";
				document.getElementsByTagName("body")[0].style.height = "auto";
			}
		},
	},
	methods: {
		toggleForm() {
			this.showSearchBox = !this.showSearchBox;
			this.active = this.showSearchBox;
			if (this.showSearchBox) {
				this.$nextTick(function () {
					this.$refs.search.focus();
				});
			}
		},
		focusItem(increment) {
			if (this.selectedItem === false) {
				if (increment < 0) {
					return;
				}
				this.selectedItem = 0;
			} else {
				this.selectedItem += increment;
			}
			if (this.selectedItem < 0) {
				this.resetItemSelection();
				return;
			}
			let counter = this.selectedItem;
			if (this.results.length > 0) {
				for (let dt in this.documentTypes) {
					// loop through all the document types to find the actual item
					let docType = this.documentTypes[dt];
					if (docType.results.length) {
						if (counter + 1 > docType.results.length) {
							// we are past this docType section so skip it completely
							counter = counter - docType.results.length;
						} else {
							// found the item in the doctype section so save the selected item info
							this.selectedSection = dt;
							this.selectedSectionItem = counter;
							this.selectedItemLink = docType.results[counter].path;
							this.$nextTick(function () {
								let searchWrapper = document.getElementById("search-results"),
									searchItem = document.getElementsByClassName("search-result item-selected");
								if (!searchItem.length) {
									return;
								}
								searchItem = searchItem[0];
								if (increment > 0 && searchItem.offsetTop > searchWrapper.clientHeight + searchWrapper.offsetTop) {
									searchWrapper.scrollTop = searchItem.offsetTop - searchWrapper.clientHeight + 80;
								} else if (increment < 0 && searchItem.offsetTop < searchWrapper.scrollTop) {
									searchWrapper.scrollTop = searchItem.offsetTop < 60 ? 0 : searchItem.offsetTop - 60;
								}
							});
							return;
						}
					}
				}
			} else if (counter < this.suggestions.length) {
				this.selectedSection = "common";
				this.selectedSectionItem = counter;
				this.selectedItemLink = this.suggestions[counter].path;
				return;
			}

			// we couldn't find anything b/c we reached the end of the list, so back up
			this.selectedItem--;
		},
		resetItemSelection() {
			this.selectedItem = false;
			this.selectedSection = false;
			this.selectedSectionItem = false;
			this.selectedItemLink = false;
		},
		clickItem() {
			if (this.selectedItemLink) {
				window.location.href = this.selectedItemLink;
			}
		},
		gtmEvent(terms) {
			if (this.gtmTimeout) {
				clearTimeout(this.gtmTimeout);
			}
			this.gtmTimeout = setTimeout(function () {
				window.dataLayer = window.dataLayer || [];
				window.dataLayer.push({
					event: "search",
					searchTerm: terms,
				});
			}, 1500);
		},
		closeSearch() {
			this.active = false;
			this.showSearchBox = false;
			this.resetItemSelection();
		},
		keyboardHandler(ev) {
			switch (ev.key) {
				case "ArrowUp":
					if (!this.active && this.results.length > 0) {
						this.active = true;
					}
					this.focusItem(-1);
					break;
				case "ArrowDown":
					if (!this.active && this.results.length > 0) {
						this.active = true;
					}
					this.focusItem(1);
					break;
				case "Enter":
					if (this.active) {
						this.clickItem();
					} else {
						this.active = true;
					}
					break;
				case "Escape":
					this.closeSearch();
					break;
				case "Tab":
					if (!this.active && this.results.length > 0) {
						this.active = true;
					}
					this.focusItem(1);
					break;
				default:
					const inp = String.fromCharCode(ev.keyCode);
					if (/[a-zA-Z0-9-_ ]/.test(inp)) {
						this.active = true;
					}
					break;
			}
		},
	},
	computed: {
		classNames() {
			let result = {};
			result["sm-search-wrapper"] = true;
			if (this.searchType) {
				result[`sm-search-${this.searchType}`] = true;
			}
			result["show-form"] = this.showSearchBox;
			return result;
		},
	},
};
</script>

<style lang="scss">
.sm-search-wrapper {
	position: relative;

	.search-results-wrapper {
		position: absolute;
		box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
		width: 100%;
		z-index: 1;
	}

	&.sm-search-header {
		position: fixed;
		right: 10px;
		top: 4px;
		z-index: 9000;

		.search-results-wrapper {
			max-height: calc(100vh - 49px);
			top: 49px;
		}

		.sm-search-form {
			.search-input-icon {
				color: var(--sm-blue);
			}
		}
	}

	.search-icon {
		color: #fff;
		font-size: 22px;
		&.inverted {
			color: var(--sm-blue);
		}
	}

	.sm-search-form {
		box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.15);
		position: relative;

		.search-input-icon {
			position: absolute;
			left: 12px;
			top: 14px;
			color: var(--sm-gold);
			z-index: 10;
			font-size: 20px;
		}

		.search-input {
			padding-left: 40px;
			padding-right: 80px;
			font-size: 18px;
			width: 100%;
			border-style: unset;
			box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.15);
			border: 1px solid rgba(0, 0, 0, 0.2);
			height: 48px;

			.sm-search-header & {
				height: auto;
			}

			&::placeholder {
				color: rgba(0, 0, 0, 0.4);
			}

			&:focus-within {
				outline: 0;
				box-shadow: none;
				border: 1px solid var(--sm-blue);
			}
		}

		.search-input-close {
			color: var(--sm-blue);
			position: absolute;
			z-index: 10;
			right: 12px;
			top: 6px;
			font-size: 22px;
		}

		.search-working {
			position: absolute;
			right: 2.3em;
			top: 0.3em;
			z-index: 10;
		}
	}

	.search-results-wrapper {
		.search-results {
			position: relative;
			left: 0;
			background: #fff;
			width: 100%;
			max-height: calc(100vh - 49px);
			z-index: 1;
			overflow-y: auto;

			.result-category {
				border-bottom: 1px solid var(--sm-light-gray);
			}

			.result-category:last-of-type {
				border-bottom: none;
			}

			&.search-no-results,
			&.search-error {
				padding-left: 30px;
			}
		}
	}

	&.sm-search-header.show-form {
		top: 0;
		left: 0;
		width: 100%;
		background: #fff;
		z-index: 9999999;

		.search-icon {
			display: none;
		}

		.sm-search-form {
			display: block !important;
			width: 100%;

			.search-input {
				height: 48px;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 2;
				border: none;
				box-shadow: 0px 3px 3px var(--sm-light-gray);

				&:focus-within {
					outline: 0;
				}
			}
		}
	}
	body.admin-bar & {
		transform: translateY(46px);
	}
}

@media only screen and (min-width: 901px) {
	.sm-search-wrapper {
		.sm-search-form {
			.search-working {
				right: 0.5em;
				// top: 0;
			}
		}

		&.sm-search-header {
			margin-top: 1px;
			max-width: 400px;
			width: calc(100% - 700px);
			min-width: 300px;
			left: 370px;

			.search-results-wrapper {
				max-height: calc(100vh - 36px);
				top: 36px;

				.search-results {
					max-height: calc(100vh - 36px);
				}
			}

			.search-input-icon {
				top: 6px;
			}

			.search-input {
				font-size: 16px;
				height: 34px;
				line-height: 33px;
				padding-right: 50px;
			}

			.search-working {
				top: 0;
			}
		}
		body.admin-bar & {
			transform: translateY(32px);
		}
	}
}
</style>
