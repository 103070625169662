import Testimonials from "./components/Testimonials.vue";
import PersonalLoanCalculator from "./components/PersonalLoanCalculator.vue";
import SearchBox from "./components/SearchBox.vue";
import StickyCta from "@common/components/widgets/StickyCta.vue";
import TopProductModal from "@common/components/widgets/TopProductModal.vue";
import CreditScore from "@common/components/inputFields/CreditScore.vue";
import settings from "@common/data/settings.js";
import vueCustomElement from "vue-custom-element";
import { getCookie } from "@common/helpers";
import store from "./store";

Vue.config.productionTip = false;
Vue.config.devtools = settings.devtools;
axios.defaults.withCredentials = true;

if (typeof bestOfData !== "undefined" && bestOfData && bestOfData.products && bestOfData.products.length) {
	const hideBusinessIds = getCookie("hb").split(",");
	const filteredProducts = bestOfData.products.filter(
		(product) => !hideBusinessIds.includes(product.business.id.toString())
	);
	if (filteredProducts.length) {
		bestOfData.topProduct = filteredProducts[0];
	}
}
StickyCta.mounted = function () {
	this.category = bestOfData.category;
	this.OfferEngine = bestOfData.OfferEngine;
	this.supportedVerticals = bestOfData.supportedVerticals;
	this.webpEnabled = bestOfData.webpEnabled;
	this.topProduct = bestOfData.topProduct;
	this.products = bestOfData.products;
};

TopProductModal.mounted = function () {
	this.category = bestOfData.category;
	this.supportedVerticals = bestOfData.supportedVerticals;
	this.webpEnabled = bestOfData.webpEnabled;
	this.products = bestOfData.products;
	this.topProduct = bestOfData.topProduct;
	this.OfferEngine = bestOfData.OfferEngine;
};

Vue.use(vueCustomElement);
StickyCta.store = store;
TopProductModal.store = store;

Vue.customElement("search-box", SearchBox);
Vue.customElement("personal-loan-calculator", PersonalLoanCalculator);
Vue.customElement("widget-testimonials", Testimonials);
Vue.customElement("sticky-cta", StickyCta);
Vue.customElement("top-product-modal", TopProductModal);
Vue.customElement("credit-score", CreditScore);
