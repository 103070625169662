export default {
	namespaced: true,
	state: {
		experiment: "",
		experimentID: ""
	},
	mutations: {
		setExperiment(state, value) {
			state.experiment = value;
		},
		setExperimentID(state, value) {
			state.experimentID = value;
		}
	},
	actions: {
		setExperiment({ commit }, value) {
			commit("setExperiment", value);
		},
		setExperimentID({ commit }, value) {
			commit("setExperimentID", value);
		}
	}
};
