// TODO - move other props into this shared one on next non quick fix

export default {
	props: {
		saveMetricOnStepChange: {
			type: Boolean,
			default: true,
		},
		ariaLabel: {
			type: String,
			default: "input field",
		},
		continueOnEnter: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		getValue() {
			return this.value;
		},
		async continueOnEnterHandler(event) {
			if (this.continueOnEnter && event.key === "Enter") {
				this.$emit("continueOnEnterHandler", event);
			}
		},
	},
};
