<template>
	<div class="results-section">
		<div
			class="results-section-title"
			id="searchResultsSection"
			aria-live="assertive"
			aria-label="Search Results Shown"
		>
			{{ title }}
		</div>
		<search-result
			v-for="(result, index) in results"
			:key="result.path"
			:pageTitle="result.title"
			:icon="result.icon"
			:link="result.path"
			:suffix="result.suffix"
			:searchTerms="searchTerms"
			:selected="active && selectedItem == index"
		></search-result>
	</div>
</template>

<script>
export default {
	name: "SearchResultsSection",
	components: {
		SearchResult: () => import(/* webpackChunkName: "searchresult" */ "./SearchResult.vue"),
	},
	props: ["title", "results", "searchTerms", "active", "selectedItem"],
	data() {
		return {};
	},
};
</script>

<style lang="scss">
.results-section {
	padding: 12px 0;
	text-align: left;
	.results-section-title {
		text-transform: uppercase;
		font-size: 12px;
		letter-spacing: 1px;
		margin-bottom: 4px;
		padding: 0 15px;
	}
}
</style>
