import settings from "../../data/settings.js";

const apiUrl = settings.httpProt + settings.apiDomain + ":" + settings.apiPort;
const metricToFields = {
	"user.contacts.email": "email",
	"user.taxes.cnc-status": "cncStatus",
	"user.taxes.installment-payments": "existingInstallmentAgreement",
	"user.taxes.agency": "taxDebtType",
	"user.location.state": "state",
	"user.taxes.tax-debt-estimated": "taxDebtAmount",
	"user.personal.last-name": "lastName",
	"user.personal.first-name": "firstName",
	"user.taxes.installment-default-risk": "installmentDefaultRisk",
	"user.taxes.cnc-months-left": "cncMonthsLeft",
	"user.contacts.phone": "phone",
	"user.inquiries.personal-loan.amount": "loanAmount",
	"user.debt-settlement-lead-capture.debt-owed": "unsecuredDebt",
	"user.debt-settlement-quote-funnel.debt-owed": "unsecuredDebt",
	"user.inquiries.personal-loan.purpose-main": "loanReasonMain",
	"user.inquiries.personal-loan.purpose": "loanReasonLevel2",
	"user.inquiries.has-co-borrower": "coBorrower",
	"user.credit-profile.credit-score-estimated": "creditScore",
	"user.personal-finance.annual-income": "annualIncome",
	"user.employment.status": "employmentStatus",
	"user.employment.pay-frequency": "payFrequency",
	"user.employment.income-type": "payMethod",
	"user.military.active-status": "militaryStatus",
	"user.residency.status": "residencyStatus",
	"user.inquiries.checking-account": "checkingAccount",
	"user.inquiries.home-ownership": "housingStatus",
	"user.location.street-address": "address1",
	"user.location.city": "city",
	"user.location.zip": "zip",
	"user.personal.dob": "dob",
	"user.inquiries.has-co-borrower": "coBorrower",
};

export default {
	namespaced: true,
	state: {
		errors: 0, // error count
		lastEdit: null,
		inputFields: {
			dob: { value: null, errorMsg: null, edited: false, storage: "none" },
			dobCo: { value: null, errorMsg: null, edited: false, storage: "none" },
			email: { value: null, errorMsg: null, edited: false, storage: "none", confirmed: false },
			emailCo: { value: null, errorMsg: null, edited: false, storage: "none" },
			phone: { value: null, errorMsg: null, edited: false, storage: "none", confirmed: false },
			phoneCo: { value: null, errorMsg: null, edited: false, storage: "none", confirmed: false },
			militaryStatus: { value: null, errorMsg: null, edited: false },
			residencyStatus: { value: null, errorMsg: null, edited: false },
			firstName: { value: null, errorMsg: null, edited: false, storage: "none" },
			lastName: { value: null, errorMsg: null, edited: false, storage: "none" },
			firstNameCo: { value: null, errorMsg: null, edited: false, storage: "none" },
			lastNameCo: { value: null, errorMsg: null, edited: false, storage: "none" },
			loanAmount: { value: null, errorMsg: null, edited: false },
			loanReason: { value: null, errorMsg: null, edited: false },
			creditScore: { value: null, errorMsg: null, edited: false },
			creditScoreBucket: { value: null, errorMsg: null, edited: false },
			highestEducation: { value: null, errorMsg: null, edited: false },
			employmentStatus: { value: null, errorMsg: null, edited: false },
			annualIncome: { value: null, errorMsg: null, edited: false },
			annualIncomeCo: { value: null, errorMsg: null, edited: false },
			payFrequency: { value: null, errorMsg: null, edited: false },
			payMethod: { value: null, errorMsg: null, edited: false },
			checkingAccount: { value: null, errorMsg: null, edited: false },
			housingStatus: { value: null, errorMsg: null, edited: false },
			address1: { value: null, errorMsg: null, edited: false, storage: "none" },
			city: { value: null, errorMsg: null, edited: false, storage: "none" },
			state: { value: null, errorMsg: null, edited: false },
			zip: { value: null, errorMsg: null, edited: false, storage: "none" },
			address1Co: { value: null, errorMsg: null, edited: false, storage: "none" },
			cityCo: { value: null, errorMsg: null, edited: false, storage: "none" },
			stateCo: { value: null, errorMsg: null, edited: false },
			zipCo: { value: null, errorMsg: null, edited: false, storage: "none" },
			addressFull: { value: null, errorMsg: null, edited: false, storage: "none" },
			addressFullCo: { value: null, errorMsg: null, edited: false, storage: "none" },
			taxDebtType: { value: null, errorMsg: null, edited: false },
			taxDebtAmount: { value: null, errorMsg: null, edited: false },
			existingInstallmentAgreement: { value: null, errorMsg: null, edited: false },
			installmentDefaultRisk: { value: null, errorMsg: null, edited: false },
			cncStatus: { value: null, errorMsg: null, edited: false },
			cncMonthsLeft: { value: null, errorMsg: null, edited: false },
			ownsVehicle: { value: null, errorMsg: null, edited: false, storage: "none" },
			ssn: { value: null, errorMsg: null, edited: false, storage: "none" },
			ssnCo: { value: null, errorMsg: null, edited: false, storage: "none" },
			optIn: { value: null, errorMsg: null, edited: false },
			userIntent: { value: null, errorMsg: null, edited: false },
			unfiledReturn: { value: null, errorMsg: null, edited: false },
			unfiledReturns: { value: null, errorMsg: null, edited: false },
			recievedTaxBenefits: { value: null, errorMsg: null, edited: false },
			unfiledReason: { value: null, errorMsg: null, edited: false },
			password: { value: null, errorMsg: null, edited: false, storage: "none" },
			selectedPersonalLoanPartners: { value: [], errorMsg: null, edited: false, storage: "none" },
			selectedPLOCPartners: { value: [], errorMsg: null, edited: false, storage: "none" },
			selectedPartners: { value: [], errorMsg: null, edited: false, storage: "none" },
			selectedCampaigns: { value: [], errorMsg: null, edited: false, storage: "none" },
			selectedDebtPartners: { value: [], errorMsg: null, edited: false, storage: "none" },
			selectedDebtCampaigns: { value: [], errorMsg: null, edited: false, storage: "none" },
			tryAgainTaxPartners: { value: [], errorMsg: null, edited: false, storage: "none" },
			tryAgainDebtPartners: { value: [], errorMsg: null, edited: false, storage: "none" },
			loanReasonMain: { value: null, errorMsg: null, edited: false },
			loanReasonLevel2: { value: null, errorMsg: null, edited: false },
			educationType: { value: null, errorMsg: null, edited: false },
			businessProfit: { value: null, errorMsg: null, edited: false },
			autoPurpose: { value: null, errorMsg: null, edited: false },
			unsecuredDebt: { value: null, errorMsg: null, edited: false },
			autoLoanDebt: { value: null, errorMsg: null, edited: false },
			mortgageDebt: { value: null, errorMsg: null, edited: false },
			studentLoanDebt: { value: null, errorMsg: null, edited: false },
			privateStudentDebt: { value: null, errorMsg: null, edited: false },
			coBorrower: { value: null, errorMsg: null, edited: false },
			includeTax: { value: null, errorMsg: null, edited: false, storage: "none" },
			includeDebt: { value: null, errorMsg: null, edited: false, storage: "none" },
			loanMatchesInfo: { value: null, errorMsg: null, edited: false },
			partnerUID: { value: [], errorMsg: null, edited: false, storage: "none" },
			loanReasonSelectGrid: { value: null, errorMsg: null, edited: false },
			monthlyPaymentsUnpayable: { value: null, errorMsg: null, edited: false },
			debtConsolidationAlternative: { value: null, errorMsg: null, edited: false },
			debtReliefAlternative: { value: null, errorMsg: null, edited: false },
			studentLoanRefinancingAlternative: { value: null, errorMsg: null, edited: false },
			initialDeposit: { value: null, errorMsg: null, edited: false },
			negativeBalance: { value: null, errorMsg: null, edited: false },
		},
		cookiesBlocked: false,
	},
	actions: {
		setInputValue({ dispatch, commit }, inputValue) {
			const val = inputValue.value;
			// custom alphabet case handling for input's value
			switch (inputValue.key) {
				case "coBorrower":
					if (typeof val === "string" && val.length > 0) {
						inputValue.value = val[0].toUpperCase() + val.slice(1).toLowerCase();
					}
					dispatch("setErrorCount");
					break;
				case "checkingAccount":
					if (typeof val === "string" && val.length > 0) {
						inputValue.value = val.toUpperCase();
					}
					break;
			}

			commit("setInputValue", inputValue);
			commit("setInputEdited", { key: inputValue.key, value: true });
			commit("setLastEdit", Date.now());
		},
		setLastEdit({ commit }, val) {
			commit("setLastEdit", val);
		},
		setInputErrorMsg({ commit, dispatch }, inputErrorMsg) {
			commit("setInputErrorMsg", inputErrorMsg);
			dispatch("setErrorCount");
		},
		setErrorCount({ commit, state }) {
			let errCount = 0;
			let fields = this.state.inputStore.inputFields;
			let fieldKeys = Object.keys(fields);

			for (let index = 0; index < fieldKeys.length; index++) {
				let key = fieldKeys[index];
				if (fields[key]["errorMsg"] !== null) {
					if (
						state.inputFields.coBorrower.value === null ||
						state.inputFields.coBorrower.value === "Yes" ||
						!(state.inputFields.coBorrower.value === "No" && key.endsWith("Co"))
					) {
						errCount++;
					}
				}
			}
			commit("setErrorCount", errCount);
		},
		resetState({ commit }) {
			// update all value
			commit("setErrorCount", 0);
			let fields = this.state.inputStore.inputFields;

			for (let key in fields) {
				commit("setInputValue", { key, value: null });
				commit("setInputErrorMsg", { key, errorMsg: null });
				commit("setInputEdited", { key, value: false });
			}
		},
		resetStateByInput({ commit }, inputFields) {
			for (let i in inputFields) {
				const key = inputFields[i];
				commit("setInputValue", { key, value: null });
				commit("setInputErrorMsg", { key, errorMsg: null });
				commit("setInputEdited", { key, value: false });
			}
		},
		async updateStore({ dispatch }, { userData, metricData, overrideData }) {
			/*
				this is used to update the store values using the data passed from a combination of the userData, old application data
				associated with the logged in user, or data that we will override with (possibly the query param data)
			*/
			const dispatches = [];
			const saved = {};
			const fields = this.state.inputStore.inputFields;
			if (!this.cookiesBlocked) {
				for (let i in fields) {
					let value = null;

					if (fields[i].storage === "none") {
						continue;
					}
					try {
						if (fields[i].storage === "session") {
							value = sessionStorage.getItem(i);
						} else {
							value = localStorage.getItem(i);
						}
					} catch (e) {
						// storage exceptions in incognito
					}
					if (value !== null) {
						saved[i] = true;
						dispatches.push(dispatch("setInputValue", { key: i, value: value }));
					}
				}
			}

			if (metricData) {
				for (let j in metricData) {
					if (!metricData[j]) {
						continue;
					}
					const fieldName = metricToFields[metricData[j].metricName];
					const field = fields[fieldName];
					if (!field) {
						continue;
					}
					// Don't override data we got from local storage with metrics data
					if (fieldName && !saved[fieldName]) {
						// if metric data is a bool, add custom handling and custom handling for main loan reason
						switch (fieldName) {
							case "loanReasonMain":
								dispatches.push(dispatch("setInputValue", { key: fieldName, value: metricData[j].valueStr }));
								dispatches.push(dispatch("setInputValue", { key: "loanReason", value: metricData[j].valueStr }));
								break;
							case "coBorrower":
								dispatches.push(
									dispatch("setInputValue", { key: fieldName, value: metricData[j].valueStr === "true" ? "Yes" : "No" })
								);
								break;
							case "checkingAccount":
								dispatches.push(
									dispatch("setInputValue", { key: fieldName, value: metricData[j].valueStr === "true" ? "YES" : "NO" })
								);
								break;
							default:
								dispatches.push(dispatch("setInputValue", { key: fieldName, value: metricData[j].valueStr }));
								break;
						}
					}
				}
			}
			if (userData && userData.email) {
				dispatches.push(dispatch("setInputValue", { key: "email", value: userData.email }));
				dispatches.push(dispatch("setInputValue", { key: "firstName", value: userData.first_name }));
				dispatches.push(dispatch("setInputValue", { key: "lastName", value: userData.last_name }));
				if (userData.phone) {
					dispatches.push(dispatch("setInputValue", { key: "phone", value: userData.phone }));
				}
			}
			for (let i in overrideData) {
				let data = overrideData[i];
				if (!(data.key in fields)) {
					continue;
				}
				dispatches.push(dispatch("setInputValue", { key: data.key, value: data.value }));
			}
			await Promise.all(dispatches);
		},
		preLoadData({ dispatch, rootGetters }, { overrideData, leadVertical }) {
			return new Promise((resolve, reject) => {
				Promise.all([
					dispatch("authStore/isLoggedIn", {}, { root: true }),
					axios.get(apiUrl + "/lead/getUserLeadMetrics"),
				])
					.then(async (values) => {
						const lastPersonalLoanSubmissionTime =
							values[1] && values[1].data && values[1].data.data && values[1].data.data.lastPersonalLoanSubmissionTime
								? values[1].data.data.lastPersonalLoanSubmissionTime
								: null;
						const metricsData =
							values[1] && values[1].data && values[1].data.data && values[1].data.data.leadMetrics
								? values[1].data.data.leadMetrics
								: null;
						if (leadVertical === "personal-loan") {
							if (lastPersonalLoanSubmissionTime) {
								try {
									dispatch("funnelStore/setLastPersonalLoanSubmissionTime", new Date(lastPersonalLoanSubmissionTime), {
										root: true,
									});
								} catch {
									/* if fails, we don't need it because it's not associated with a funnel */
								}
							}
							// clean out non related data
							if (metricsData && metricsData.length) {
								const includeMetrics = ["user.contacts.phone", "user.location.state"]; // make an exception for these metrics
								for (const index in metricsData) {
									const data = metricsData[index];
									if (
										!(data.dataSourceName.includes("personal_loan") || data.dataSourceName.includes("personal-loan")) &&
										!includeMetrics.includes(data.metricName)
									) {
										metricsData[index] = null;
									}
								}
							}
						}

						await dispatch("updateStore", {
							userData: rootGetters["authStore/userData"],
							metricData: metricsData,
							overrideData: overrideData,
						});
						const response = values[1].data.data ? values[1].data.data : null;
						resolve(response);
					})
					.catch((error) => {
						dispatch("updateStore", { userData: null, metricData: null, overrideData: overrideData });
						resolve();
					});
			});
		},
		setCookiesBlocked({ commit }, value) {
			commit("setCookiesBlocked", value);
		},
	},
	mutations: {
		setInputValue(state, inputValue) {
			state.inputFields[inputValue.key].value = inputValue.value;
			if (state.cookiesBlocked || state.inputFields[inputValue.key].storage === "none" || inputValue.skipLocalStorage) {
				return;
			}
			try {
				if (inputValue.value === null) {
					if (state.inputFields[inputValue.key].storage === "session") {
						sessionStorage.removeItem(inputValue.key);
					} else {
						localStorage.removeItem(inputValue.key);
					}
					return;
				}
				if (state.inputFields[inputValue.key].storage === "session") {
					sessionStorage.setItem(inputValue.key, inputValue.value);
				} else {
					localStorage.setItem(inputValue.key, inputValue.value);
				}
			} catch (e) {
				// storage exceptions in incognito
			}
		},
		setInputErrorMsg(state, inputErrorMsg) {
			state.inputFields[inputErrorMsg.key].errorMsg = inputErrorMsg.errorMsg;
		},
		setErrorCount(state, count) {
			state.errors = count;
		},
		setLastEdit(state, val) {
			state.lastEdit = val;
		},
		setInputEdited(state, { key, value }) {
			state.inputFields[key].edited = value;
		},
		setCookiesBlocked(state, value) {
			state.cookiesBlocked = value;
		},
	},
};
