<template>
	<div class="profile-nps">
		<div v-if="businessname" class="link-title">
			<a :href="/reviews/ + category + '/' + data.business.nicename" target="_blank">{{ data.business.name }}</a>
		</div>
		<div v-if="tooltip && data['total_vote_count'] > 0" class="value-and-description">
			Net Recommendation Score
			<span class="net-recommendation-score">{{ data.nps_rating > 0 ? "+" + data.nps_rating : data.nps_rating }}</span
			>%
			<i
				class="fa fa-question-circle"
				tabindex="0"
				aria-label="Icon to display a tooltip on the net recommendation score details"
			></i>
			<span class="nps-description-vue"
				><recommendation-score :data="data" :highlightsFlag="false"></recommendation-score
			></span>
		</div>
		<div v-if="data['total_vote_count'] > 4">
			<p v-if="data.nps_rating < -50" class="n-recnd repink rating-description">Strongly not recommended</p>
			<p v-else-if="data.nps_rating < 0" class="n-recnd rered rating-description">Mostly not recommended</p>
			<p v-else-if="data.nps_rating < 50" class="n-recnd regreen rating-description">Mostly recommended</p>
			<p v-else class="n-recnd regreen rating-description">Strongly recommended</p>

			<div class="progress">
				<div
					v-if="data.positive_vote_count"
					:style="'width:' + data.positive_vote_percent + '%;'"
					:data-count="data.positive_vote_count"
					:aria-valuetext="`${data.positive_vote_count} positive review${data.positive_vote_count == 1 ? '' : 's'}`"
					role="meter"
					class="progress-bar progress-bar-yes"
				>
					<span v-if="data.positive_vote_percent > 10">{{ data.positive_vote_count }}</span>
					<span v-else>&nbsp;</span>
				</div>
				<div
					v-if="data.passive_vote_count"
					:style="'width:' + data.passive_vote_percent + '%;'"
					:data-count="data.passive_vote_count"
					:aria-valuetext="`${data.passive_vote_count} neutral review${data.passive_vote_count == 1 ? '' : 's'}`"
					role="meter"
					class="progress-bar progress-bar-maybe"
				>
					<span v-if="data.passive_vote_percent > 10">{{ data.passive_vote_count }}</span>
					<span v-else>&nbsp;</span>
				</div>
				<div
					v-if="data.negative_vote_count"
					:style="'width:' + data.negative_vote_percent + '%;'"
					:data-count="data.negative_vote_count"
					:aria-valuetext="`${data.negative_vote_count} negative review${data.negative_vote_count == 1 ? '' : 's'}`"
					role="meter"
					class="progress-bar progress-bar-no"
				>
					<span v-if="data.negative_vote_percent > 10">{{ data.negative_vote_count }}</span>
					<span v-else>&nbsp;</span>
				</div>
			</div>
			<div class="nps-progress-row nps-vote-count">{{ data["total_vote_count"] }} total votes</div>
			<div v-if="link && data.parent" class="reviews-links hide-mobile-xs">
				<a
					class="reviews-link"
					:href="'/reviews/' + data.category.nicename + '/' + data.business.nicename + '#title-reviews'"
					target="_blank"
					:data-business-name="data.business.name"
					:data-category-name="data.category.nicename"
					>Browse All Reviews</a
				>
			</div>
		</div>
		<div v-else-if="data['total_vote_count'] > 0">
			<p class="nps-not-determined rating-description">
				Rating not yet determined
				<i
					class="fa fa-question-circle-o"
					onmouseover="showTooltip(this)"
					onmouseout="hideTooltip(this)"
					onfocus="showTooltip(this)"
					onfocusout="hideTooltip(this)"
					ontouchstart="toggleTooltip(this)"
					tabindex="0"
					>&nbsp;</i
				>
			</p>
			<p class="reviews-count">{{ data["total_vote_count"] }} total votes</p>
			<span class="nps-rating-description"
				>In our efforts to provide the community with the most accurate information, recommendation rating is not
				determined until a sufficient number of SuperMoney users cast their vote</span
			>
			<div v-if="link && data.parent" class="reviews-links hide-mobile-xs">
				<a
					class="reviews-link"
					:href="'/reviews/' + data.category.nicename + '/' + data.business.nicename + '#title-reviews'"
					target="_blank"
					:data-business-name="data.business.name"
					:data-category-name="data.category.nicename"
					>Browse All Reviews</a
				>
			</div>
		</div>
		<div v-else class="no-reviews">
			<div v-if="link && data.parent" class="reviews-links hide-mobile-xs">
				<a
					class="reviews-link"
					:href="'/reviews/' + data.category.nicename + '/' + data.business.nicename + '#title-reviews'"
					target="_blank"
					:data-business-name="data.business.name"
					:data-category-name="data.category.nicename"
					>Be the first to rate</a
				>
			</div>
		</div>
	</div>
</template>

<script>
// Used in Bestof, Localized
import RecommendationScore from "./RecommendationScore.vue";
export default {
	name: "ProfileNps",
	props: {
		data: Array,
		category: String,
		tooltip: Boolean,
		link: Boolean,
		businessname: Boolean,
	},
	components: {
		RecommendationScore,
	},
	computed: {
		experiment() {
			return this.$store.state.bestofStore.experiment;
		},
		experimentID() {
			return this.$store.state.bestofStore.experimentID;
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.profile-nps {
	display: inline-block;
	padding: 0.5em;
	color: var(--sm-med-gray);
	font-size: 0.9em;
	text-align: left;

	.nps-rating-description {
		display: none;
		position: absolute;
		left: 0;
		max-width: 300px;
		min-width: 200px;
		width: 80%;
		background: #eee;
		border: 1px var(--sm-light-gray) solid;
		border-radius: 3px;
		z-index: 100;
		margin-left: 0.5em;
		padding: 0.5em;
		font-size: 0.85em;
		line-height: 1.2;
	}

	.nps-not-determined {
		font-size: 0.85em;
		text-align: left;
		margin-bottom: 0;
	}

	.nps-progress-row.nps-vote-count {
		font-size: 0.85em;
	}

	.link-title {
		text-align: left;
		font-weight: bold;
	}

	.value-and-description {
		color: var(--sm-med-gray);
		margin-bottom: 4px;
		padding: 0;
		float: none;
		position: relative;
		font-size: 13px;
		display: block;
		.nps-description-vue {
			display: none;
			position: absolute;
			z-index: 10;
			font-size: 0.9em;
			border: 1px solid #ddd;
			border-radius: 3px;
		}
		.fa.fa-question-circle:hover + .nps-description-vue,
		.fa.fa-question-circle:focus + .nps-description-vue,
		.fa.fa-question-circle.focus-visible + .nps-description-vue {
			display: block;
		}
	}

	.rating-description {
		font-size: 16px;
		font-weight: 700;
		width: auto;
		text-align: left;
		margin: 0 0 5px;
		line-height: 1;
	}
	.repink {
		color: var(--sm-negative-rating);
	}
	.rered {
		color: var(--sm-negative-rating);
	}
	.regray {
		color: var(--sm-neutral-rating);
	}
	.regreen {
		color: var(--sm-positive);
	}

	.progress {
		height: 12px;
		width: 200px;
		margin: 0 10px 0 0;
		max-width: 100%;

		.progress-bar {
			display: inline;
			height: 16px;

			span {
				line-height: 16px;
			}
		}
	}

	.nps-progress-row {
		display: inline-block;

		&.nps-vote-count {
			position: relative;
			text-align: left;
			display: block;
			margin-top: 0;
			padding-top: 0;
			vertical-align: top;
		}
	}

	.reviews-link {
		display: block;
	}

	.separator {
		display: none;
	}

	.reviews-links {
		font-size: 1em;
		color: black;
		display: block;
	}

	.no-reviews {
		text-align: center;
		position: relative;
		top: 0px;
		font-size: 1em;
		text-align: left;
	}

	.reviews-count {
		font-size: 0.85em;
		margin-bottom: 5px;
	}
}

@media (min-width: 576px) {
	.bestof-vertical-profile {
		.profile-nps {
			.reviews-links.hide-mobile-xs {
				display: block;
			}
		}
	}
}

@media (min-width: 420px) {
	.profile-nps {
		.progress {
			height: 15px;
			width: 100%;
			max-width: 200px;
			clear: left;
			float: left;
		}
		.reviews-link {
			display: inline-block;
		}

		.separator {
			display: inline;
		}
	}
}
@media (min-width: 575px) {
	.profile-nps {
		.nps-rating-description {
			left: unset;
		}
		.nps-progress-row.nps-vote-count {
			display: inline-block;
		}
	}
}
</style>
